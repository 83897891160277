@import url("https://fonts.googleapis.com/css?family=Raleway");
@import url("https://fonts.googleapis.com/css?family=Teko");

/************************************************GENERIC HTML ELEMENTS**************************************************/

a {
  text-decoration: none;
}

html {
  /*background-color: #1C202E;*/
  background: linear-gradient(115.95deg, #1e242f 100%, #0d1215 100%);
  height: 100%;
  background-attachment: fixed;
}

.orange {
  background-color: orange;
}

.gras {
  font-weight: bold;
}

/********************************************************NAVBAR**********************************************************/

/* All navbar nav*/
.navbar {
  padding-top: 1.5%;
}

/* All rankingTab div*/
.navbar-menu {
}

/* Items (Home, tournament, etc..) */
.navbar-item {
}

/* Auth buttons (login etc)*/
.authbuttons {
}
.navImg {
  width: 6vmin;
  margin-left: 2.5%;
  margin-top: -0.5%;
  position: absolute;
}

/* Buttons from navbar-items */
.navbut {
  color: white;

  margin-left: 4%;
  margin-top: 5%;

  padding-left: 2.1%;
  padding-right: 2.1%;

  text-align: center;

  font-size: 1.3vw;

  font-size: 1.2vw;
}

.navfirst {
  margin-left: 15%;
}

.navbut:hover {
  color: grey;
}

.navbutlogcont {
  padding-left: 73vw;
}

/* Buttons from authbuttons */
.navbutlog {
  color: lightgray;

  padding-left: 2vw;
  padding-right: 2vw;

  font-size: 1.4vw;
}

.navIcons {
  width: 2vw;
  position: absolute;
  top: 2%;
}

.traitGrisBandeau {
  width: 35%;
  height: 0.5%;
  background-color: grey;
  display: inline-block;
  margin-left: 3%;
  margin-top: 1.6%;
  font-size: 0.2vh;
  top: 1%;
  position: absolute;
}

.navlast {
  margin-top: 0%;
  top: 2%;
  right: 3%;
  position: absolute;
}

/********************************************************NAVBAR**********************************************************/
/********************************************************PROFILE*********************************************************/
.profile {
  float: left;
  width: 95vw;
  color: black;
  height: 85vh;

  margin-left: 2vw;
  margin-top: 0vh;

  font-size: 1vw;
}

.contentProfile {
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-left: 5%;
  background-color: #2b3149;

  color: white;
}
.contentProfileWrapper {
  float: right;
  width: 45vw;
  border-style: solid;
  border-color: white;
  border-radius: 5px;
  margin-top: 3vh;
  margin-left: 3vw;
  height: 79vh;

  background-color: #2b3149;
}

.profilePublic {
  border-radius: 16px;
  float: left;
  margin-left: 4%;
  margin-top: 6%;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  width: 36%;
  padding-bottom: 0.6%;
}

.publicProfBorder {
  float: left;
  margin-top: 10%;
  width: 1px;
  height: 30vmin;
}

.publicProfBorderRight {
  float: left;
  margin-top: 10%;
  margin-left: 3%;
  width: 1px;
  height: 30vmin;
}

.cadre {
  float: left;
  height: 100%;
}

.imgCadre {
  position: absolute;
  z-index: 1;
  max-width: 16%;
}

.imgEmbleme {
  position: absolute;
  z-index: 2;
  max-width: 16%;

  margin-left: 0vw;
}

.imgBordure {
  position: absolute;
  z-index: 3;
  max-width: 16%;

  margin-top: 0vh;
  margin-left: 0vw;
}

.imgCadreRank {
  position: absolute;
  max-width: 34%;
}

.imgEmblemeRank {
  position: absolute;
  width: 34%;
}

.imgBordureRank {
  position: absolute;
  width: 34%;
}

.pvImages2 {
  width: 50%;
  float: left;
}

.pvPseudo2 {
  float: left;
  text-align: center;
  margin-left: 45%;
  height: 100%;
  font-family: "Teko";
  font-size: 2vw;
}

.pvCitation2 {
  color: #e7ce4a;
  font-size: 2vw;
  margin-bottom: 5%;
}

.pvStat2 {
  font-size: 1.8vw;
  margin-top: 10%;
}

.ligueRankPP2 {
  max-height: 12vh;
  margin-bottom: 0%;
  margin-top: 7%;
  margin-left: 5%;
}

.jpsize2 {
  font-family: "Teko";
  text-transform: uppercase;
  font-size: 3vw;
}

.citationSelector {
  width: 40%;
  background-blend-mode: multiply;
  background-color: rgba(27, 29, 41, 0.6);
  margin-left: 8%;
  padding-top: 1.5%;
  padding-bottom: 1.2%;
  padding-left: 2%;
  margin-bottom: 2%;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(117, 117, 117, 0.6);
  color: #b3b3b3;
  font-weight: 900;
  cursor: pointer;
  display: inline-block;
}

.blueCitation {
  width: 40%;
  background-blend-mode: multiply;
  background-color: rgba(27, 29, 41, 0.6);
  margin-left: 8%;
  padding-top: 1.5%;
  padding-bottom: 1.2%;
  padding-left: 2%;
  margin-bottom: 2%;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(117, 117, 117, 0.6);
  color: #0080FF;
  font-weight: 900;
  cursor: pointer;
}

.greenShadow {
  box-shadow: 0 0 10px 3px #32d89f;
}

.citationSelectorSelected {
  background-color: #2b4e4b;
  background-color: rgba(43, 78, 75, 0.75);
  background-blend-mode: color-dodge;
  width: 40%;
  margin-left: 8%;
  padding-top: 1.5%;
  padding-bottom: 1.2%;
  padding-left: 2%;
  margin-bottom: 2%;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #32d89f;
  color: #b3b3b3;
  font-weight: 900;
  box-shadow: 0 0 10px 3px #32d89f;
  cursor: pointer;
}

.statSelector {
  cursor: pointer;
  width: 40%;
  background-blend-mode: multiply;
  background-color: rgba(27, 29, 41, 0.6);
  margin-left: 8%;
  padding-top: 0.5%;
  padding-bottom: 0%;
  padding-left: 2%;
  margin-bottom: 2%;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: rgba(117, 117, 117, 0.6);
  color: #b3b3b3;
  font-weight: 900;
  white-space: pre-line;
  display: inline-block;
}

.statSelectorSelected {
  cursor: pointer;
  background-color: #2b4e4b;
  background-color: rgba(43, 78, 75, 0.75);
  background-blend-mode: color-dodge;
  width: 40%;
  margin-left: 8%;
  padding-top: 0.5%;
  padding-bottom: 0%;
  padding-left: 2%;
  margin-bottom: 2%;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  border-color: #32d89f;
  color: #b3b3b3;
  font-weight: 900;
  box-shadow: 0 0 10px 3px #32d89f;
  white-space: pre-line;
}

.rightStat {
  margin-top: 5%;
}

.rightCitation {
  margin-top: 5%;
}

.rightPanelProfil {
  border-radius: 16px;
  float: left;
  margin-top: 6%;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  width: 55%;
  height: 29.3vw;
  overflow-y: auto;
  padding-top: 0.5%;
  padding-left: 1.5%;
}

.rightPanelSelector {
  padding-left: 1.5%;
  padding-right: 2.1%;
  font-size: 1.08vw;
  color: grey;
}

.rightPanelSelector:hover {
  color: white;
  cursor: pointer;
}

.traitBlanc2 {
  color: lightgray;
}

.traitBlanc2 {
  width: 105%;
  height: 1px;
  background-color: lightgray;
  margin-left: -5%;
  margin-bottom: 2%;
}

.traitBlanc3 {
  width: 105%;
  height: 1px;
  background-color: lightgray;
  margin-left: -5%;
  margin-top: 2vh;
}



.publicPseudo {
  margin-top: 5%;
  margin-left: 56%;
  height: 10%;
  padding-top: 5%;
  width: 40%;

  font-family: "Teko";
  font-weight: bold;

  text-align: center;
  line-height: 1%;
}

.teko {
  font-family: "Teko";
}

.bottomPanelProfil {
  margin-left: 13%;
  margin-top: 39%;
  color: white;
}

.publicPsd {
  color: white;
  text-transform: uppercase;
  font-size: 2.5vw;
  font-weight: bolder;
}

.citation {
  color: #e7ce4a;
  font-size: 1.4vw;
}

.publicLvl {
  margin-top: 18%;
  color: white;
  text-transform: uppercase;
  font-size: 1.3vw;
  font-weight: bolder;

  border-bottom-color: grey;
  border-bottom-style: solid;
  border-bottom-width: 2px;

  padding-bottom: 15%;
}

.stat {
  margin-left: 56%;
  margin-top: 4%;
  width: 40%;

  text-align: center;

  color: #e7ce4a;

  padding-top: 1vh;
  white-space: pre-line;
  font-size: 1.1vw;
}

.ligueRankProf {
  max-width: 70%;
  margin-left: 0%;
  margin-bottom: 35%;
}

.cadre2 {
  position: absolute;
  margin-top: 1.5vh;
  margin-left: 1vw;
}

.xpBar {
  width: 40%;
  height: 25%;

  margin-left: 55%;
  margin-top: 2%;
  border-radius: 25px;
  color: white;
  text-align: center;
}

.xpProgress {
  width: 85%;
  height: 10%;
  border-radius: 15px;
  margin-left: 10%;
  margin-top: 5%;
  margin-bottom: 5%;
  color: #11d89c;
  float: left;
}

.textExp {
  margin-left: 7.5vw;
  margin-top: 0vh;
  font-size: 1.3vw;
  color: black;
}

.xpProgressInsinde {
  width: 59%;
  height: 100%;
  background-color: #11d89c;
  border-radius: 15px;
}

.inventaireEmbleme {
  width: 95%;
  height: 15vh;
  background-color: lightgray;
  border-radius: 5px;
  overflow: auto;

  color: black;
}

.embleme {
  border-style: solid;
  border-color: black;
  margin-top: 0vh;
  margin-left: 5vw;
  width: 8vw;
  height: 14vh;
}

.soon {
  position: absolute;
  margin-top: 8vh;
  margin-left: -8.5vw;
  font-size: 1.3vw;
}

.soon2 {
  position: absolute;
  margin-top: 8vh;
  margin-left: -13vw;
  font-size: 1.3vw;
}

.imgInventaire {
  width: 100%;
}

.imgInventaireShort {
  width: 100%;
  margin-top: 39.9%;
  margin-bottom: 38%;
}

.imgInventaireShort:hover {
  box-shadow: 0 0 10px 3px #32d89f;
}

.imgInventaire:hover {
  width: 100%;
  box-shadow: 0 0 10px 3px #32d89f;
}



.imgInventaireGreen{
  width: 100%;
  box-shadow: 0 0 10px 3px #32d89f;
}

.imgSelector {
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 2%;
  width: 10%;
  float: left;
}

.imgSelectorTransaction {
  margin-right: 0%;
  width: 10%;
  float: left;
}

.greenUnderline {
  box-shadow: 0 0 10px 3px #32d89f;
}

.imgSelectorGreenShadow {
  margin-top: 5%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;
  border-bottom-color: grey;
  border-bottom-style: solid;
  width: 10%;
  float: left;
  box-shadow: 0 0 10px 3px #32d89f;
}

.imgSelector:hover {
  border-bottom-color: #32d89f;
}

.imgCitation {
  width: 4vw;
  margin-left: 1vw;
  margin-top: -2.4vh;
}

.imgStat {
  width: 4vw;
  margin-left: 1vw;
  margin-top: -2.4vh;
}

.inventaireCadre {
  width: 95%;
  height: 11vh;
  background-color: lightgray;
  border-radius: 5px;
  margin-top: 1vh;
  overflow: auto;
  color: black;
}

.inventaireCitationConteneur {
  background-color: lightgray;
  border-radius: 5px;

  width: 95%;
  height: 11vh;
  margin-top: 1vh;

  color: black;
}
.inventaireCitation {
  padding-top: 3vh;
  height: 100%;
  overflow: auto;
}

.blocInventaireCitation {
  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;

  margin-right: 0.7vw;
  margin-left: 0.6vw;

  text-align: center;

  background-color: #283f55;
  color: white;

  font-size: 1vw;

  border-style: solid;
  border-color: #1c202e;
  border-radius: 5px;

  cursor: copy;
}

.inventaireStatConteneur {
  background-color: lightgray;
  border-radius: 5px;

  width: 95%;
  height: 12vh;
  margin-top: 1vh;

  color: black;
}

.inventaireStat {
  width: 95%;
  height: 10vh;
  margin-top: 1vh;
  overflow: auto;
  padding-top: 3vh;
}

.blocInventaireStat {
  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;

  margin-right: 0.7vw;
  margin-left: 0.6vw;

  text-align: center;

  background-color: #283f55;
  color: white;

  font-size: 1vw;

  border-style: solid;
  border-color: #1c202e;
  border-radius: 5px;

  cursor: copy;
}

.imgCadreIventaire {
  width: 4vw;
  margin-left: 1vw;
  margin-top: 0.7vh;
}

.userSpecs {
  background-color: #2b3149;
  color: white;

  padding-top: 2vw;
  padding-left: 2vw;
  width: 90%;
  border-radius: 2vh;
}

.rankingC {
  display: none;
}

.complex {
  float: left;
  width: 25vw;

  border-style: solid;
  border-color: white;
}

.stratBut {
  float: left;
  margin-top: 3vh;
  font-size: 1vw;
  margin-left: 0.5vw;
  padding: 0.5vw;
  background-color: darkslategrey;
  color: white;
  border-radius: 7px;
  cursor: pointer;
}
/********************************************************PROFILE*********************************************************/
/********************************************************HOME*********************************************************/
.home {
  color: whitesmoke;
}

.slogan {
  margin-top: 0vh;
  margin-left: 10vw;

  float: left;
}

.allHomeContent {
  margin-top: 10vh;
  height: 100%;
  width: 100%;
}

.gifHome {
  height: 100%;
  width: 100%;
  background-color: #1c202e;
  text-align: center;
  position: relative;
  display: inline-block;
}

.home {
  display: none;
  overflow: hidden;
}

.gifHomeEnter {
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 10px;
  padding: 30px 65px;
  font-size: 2vw;
  font-family: Helvetica;
  color: #fff;

  margin-left: 43vw;
  margin-top: 45vh;

  background-color: #283f55;
  border-radius: 10%;
  border-width: 3px;
  border-style: solid;
  border-top-color: #ff5757;
  border-right-color: #ff5757;
  border-left-color: #08664a;
  border-bottom-color: #08664a;

  cursor: pointer;
  float: left;
}

.gifHomePlayers {
  position: absolute;
  left: 0px;
  top: 0px;

  font-size: 2vw;
  color: #fff;

  margin-left: 56vw;
  margin-top: 50vh;

  background-color: rgba(0, 0, 0, 0);
}

.gifHomeDiscord {
  position: absolute;
  left: 0px;
  top: 0px;

  font-size: 2vw;
  color: #fff;

  margin-left: 80vw;
  margin-top: 80vh;

  width: 5vw;
  height: 9.5vh;

  cursor: pointer;
}

.gifHomeTwit {
  position: absolute;
  left: 0px;
  top: 0px;

  font-size: 2vw;
  font-family: Helvetica;
  color: #fff;

  margin-left: 87vw;
  margin-top: 80vh;

  width: 5vw;
  height: 9.5vh;

  cursor: pointer;
}

.gifHomeInsta {
  position: absolute;
  left: 0px;
  top: 0px;

  font-size: 2vw;
  font-family: Helvetica;
  color: #fff;

  margin-left: 73vw;
  margin-top: 80vh;

  width: 5vw;
  height: 9.5vh;

  cursor: pointer;
}

.gifHomeDiscord2 {
  left: 0px;
  top: 0px;

  font-size: 2vw;
  color: #fff;

  margin-right: 8vw;
  margin-top: 0.4vh;

  width: 5vw;
  height: 9.5vh;
  float: right;
  cursor: pointer;
}

.gifHomeTwit2 {
  left: 0px;
  top: 0px;

  font-size: 2vw;
  font-family: Helvetica;
  color: #fff;

  margin-right: 0.3vw;
  margin-top: 0vh;

  width: 5vw;
  height: 9.5vh;
  float: right;
  cursor: pointer;
}

.gifHomeInsta2 {
  left: 0px;
  top: 0px;

  font-size: 2vw;
  font-family: Helvetica;
  color: #fff;
  float: right;
  margin-left: 0vw;
  margin-top: 0vh;

  width: 5vw;
  height: 9.5vh;
}

.gifHomeEnter
{
    position: absolute;
    left: 0px;
    top: 0px;
    margin: 10px;
    padding: 30px 65px;
    font-size: 2vw;
    font-family: Helvetica;
    color: #FFF;
    
    margin-left: 43vw;
    margin-top: 45vh;
    
    background-color: #283f55;
    border-radius: 10%;
    border-width:3px;
    border-style:solid;
    border-top-color:#FF5757;
    border-right-color:#FF5757;
    border-left-color:#08664A;
    border-bottom-color:#08664A;
    
    cursor: pointer;
    float: left;

}

.gifHomePlayers
{
    position: absolute;
    left: 0px;
    top: 0px;
    
    font-size: 2vw;
    color: #FFF;
    
    margin-left: 56vw;
    margin-top: 50vh;
    
    background-color: rgba(0, 0, 0, 0);

}


.gifHomeDiscord
{
    position: absolute;
    left: 0px;
    top: 0px;
    
    font-size: 2vw;
    color: #FFF;
    
    margin-left: 80vw;
    margin-top: 80vh;
    
    
    width: 5vw;
    height: 9.5vh;
    
    cursor: pointer;
}


.gifHomeTwit
{
    position: absolute;
    left: 0px;
    top: 0px;
    
    font-size: 2vw;
    font-family: Helvetica;
    color: #FFF;
    
    margin-left: 87vw;
    margin-top: 80vh;
    
    width: 5vw;
    height: 9.5vh;
    
    cursor: pointer;
}


.gifHomeInsta
{
    position: absolute;
    left: 0px;
    top: 0px;
    
    font-size: 2vw;
    font-family: Helvetica;
    color: #FFF;
    
    margin-left: 73vw;
    margin-top: 80vh;
    
    
    width: 5vw;
    height: 9.5vh;
    
    cursor: pointer;
}




.gifHomeDiscord2
{
    
    left: 0px;
    top: 0px;
    
    font-size: 2vw;
    color: #FFF;
    
    margin-right: 8vw;
    margin-top: 0.4vh;
    
    
    width: 5vw;
    height: 9.5vh;
    float: right;
    cursor: pointer;
}


.gifHomeTwit2
{
    
    left: 0px;
    top: 0px;
    
    font-size: 2vw;
    font-family: Helvetica;
    color: #FFF;
    
    margin-right: 0.3vw;
    margin-top: 0vh;
    
    width: 5vw;
    height: 9.5vh;
    float: right;
    cursor: pointer;
}


.gifHomeInsta2
{
    
    left: 0px;
    top: 0px;
    
    font-size: 2vw;
    font-family: Helvetica;
    color: #FFF;
    float: right;
    margin-left: 0vw;
    margin-top: 0vh;
    
    
    width: 5vw;
    height: 9.5vh;
    
    cursor: pointer;
}

.imgDiscord
{
    width: 3.5vw;
    cursor: pointer;
    float: left;
    margin-left: 15%;
}

.imgTwit
{
    width: 4vw;
    float: left;
    margin-left: 1.5%;
    cursor: pointer;
}

.imgTwit2
{
    width: 3vw;
    position: relative;
    top: 14px;
}

.imgInsta
{
    width: 4vw;
    float: left;
    margin-left: 1.4%;
    cursor: pointer;
}
.HomeSlogan
{
    font-size: 7vw;
    font-weight: 800;
    color: #FFF;
    margin-left: 5%;
    margin-top: 5%;
    float: left;
    width: 48%;

    line-height: 0.5;
}


.HomeSloganImg
{
    width: 40%;
    float: left;
    margin-top: 0%;
    margin-left: 4%;
}


.graphAcceuil
{
    margin-top: 10%;
    max-width: 85%;
    box-shadow: 0 0 10px 4px black;
    border-style: solid;
    border-color: darkslategrey;
    border-width: 1px;
}

.sloganTitreBeta
{
    color: #1E242F;
    font-style: normal;
    font-weight: 800;
    text-shadow: -1px 0 #7AFFD7, 0 1px #7AFFD7, 1px 0 #7AFFD7, 0 -1px #7AFFD7, 0px 4.89px 29.35px rgba(122, 255, 215, 0.8);
}


.centeredContent
{
    margin-top: 26%;
    width: 100%;
    text-align: center;
    font-size: 1.5vw;
    color: white;
    overflow-x: hidden;
    cursor: pointer;
}

.imgDiscord {
  width: 3.5vw;
  cursor: pointer;
  float: left;
  margin-left: 15%;
}

.imgTwit {
  width: 4vw;
  float: left;
  margin-left: 1.5%;
  cursor: pointer;
}

.imgTwit2 {
  width: 3vw;
  position: relative;
  top: 14px;
}

.imgInsta {
  width: 4vw;
  float: left;
  margin-left: 1.4%;
  cursor: pointer;
}
.HomeSlogan {
  font-size: 7vw;
  font-weight: 800;
  color: #fff;
  margin-left: 5%;
  margin-top: 5%;
  float: left;
  width: 48%;

  line-height: 0.5;
}

.HomeSloganImg {
  width: 40%;
  float: left;
  margin-top: 0%;
  margin-left: 4%;
}

.graphAcceuil {
  margin-top: 10%;
  max-width: 85%;
}

.sloganTitreBeta {
  color: #1e242f;
  font-style: normal;
  font-weight: 800;
  text-shadow: -1px 0 #7affd7, 0 1px #7affd7, 1px 0 #7affd7, 0 -1px #7affd7,
    0px 4.89px 29.35px rgba(122, 255, 215, 0.8);
}

.centeredContent {
  margin-top: 26%;
  width: 100%;
  text-align: center;
  font-size: 1.5vw;
  color: white;
  overflow-x: hidden;
}

.centeredContent2 {
  margin-top: 4%;
  width: 100%;
  text-align: center;
  font-size: 1.5vw;
  color: white;
}

.centeredContent3 {
  margin-top: 15%;
  margin-bottom: 5%;
  margin-left: 25%;
  width: 50%;
  text-align: center;
  font-size: 1.5vw;
  color: white;
}

.AccAssets {
  width: 40%;
  background-color: red;
  margin-left: 35%;
  margin-top: 1%;
}

.assetBloc {
  width: 10%;
  background-color: white;
  float: left;
  margin-right: 2%;
  padding-right: 1%;
  border-radius: 20px;
}

.imgAssetsBitcoin {
  max-width: 100%;
  margin-top: 5%;
  margin-left: 5%;
}

.centeredTitle {
  font-size: 4vw;
  color: white;
  width: 100%;
  margin-top: 18%;
  font-weight: 800;
}

.underLineStat {
  width: 130%;
  margin-left: -15%;
}

.bandeauLegal {
  color: grey;
  width: 100%;
  height: 30%;
  background-color: #444444;
  text-align: center;
  padding-top: 1%;
  padding-bottom: 3%;
  font-size: 0.8vw;
}

.imgRecomp {
  width: 80%;
  margin-left: 10%;
  margin-top: 5%;
}

.imgRecompA {
  max-width: 20%;
}

.btAcc {
  padding: 0.3%;
  color: black;
  background-color: #ff5757;
  border-style: none;
  border-radius: 10px;
  font-size: 0.72vw;
}

.sloganCaps {
  font-size: 2.7vw;
  line-height: 1%;
}

.sloganFake {
  font-size: 2vw;
  font-weight: bold;
}

.rankingHome {
  margin-left: 70vw;
  margin-top: 2vh;

  font-size: 1.3vw;

  padding-left: 2vw;
  padding-top: 2vh;
  padding-bottom: 2vh;

  background-color: #2b3149;

  border-style: solid;
  border-color: white;
  border-radius: 2%;

  width: 22vw;
}

.rankingTitre {
  font-size: 2vw;
}

.leftVideo {
  float: left;
  width: 26vw;
  margin-top: 28vh;

  left: 0;
  position: absolute;
}

.mid {
  padding-top: 5vw;

  text-align-last: center;

  font-size: 1.1vw;
  display: block;

  float: left;
  width: 40vw;

  height: 50vh;
  margin-left: 28.5vw;
}

.rightVideo {
  width: 27.5vw;
  margin-top: 28vh;
  position: absolute;

  right: 0;
}

.ranking {
  margin-top: 3vh;
}

.midTitre {
  font-size: 1.8vw;
  margin-left: 0vw;
}

.midText {
  font-size: 1.5vw;

  margin-left: 0vw;
  margin-bottom: 5vh;
}

.butplay {
  color: white;
  height: 7vh;
  width: 10vw;

  display: block;

  margin-bottom: 2vw;
  margin-top: 0.5vw;
  margin-left: 15vw;

  text-align: center;
  vertical-align: middle;
  line-height: 7vh;

  font-size: 1.6vw;
  background-color: #2b3149;
  border-radius: 10%;
  border-width: 3px;
  border-style: solid;
  border-top-color: #ff5757;
  border-right-color: #ff5757;
  border-left-color: #08664a;
  border-bottom-color: #08664a;
}

.midSecond {
  margin-top: 75vh;
  font-size: 1.8vw;
  margin-left: 0vw;

  text-align: center;
}

.assets {
  border-radius: 5px;
  border-width: 5px;
  border-style: solid;

  font-size: 1.4vw;

  margin-right: 0vw;
  margin-left: 2vw;

  margin-top: 2vh;

  background-color: #2b3149;

  padding-top: 2vh;
  padding-bottom: 2vh;
  padding-left: 4vw;
  padding-right: 4vw;

  width: 50vw;
}

.imgAssetsTesla {
  width: 4vw;
  position: absolute;
  margin-top: -1.3vh;
  margin-left: -3.5vw;
}

.imgAssetsApple {
  width: 2.8vw;
  position: absolute;
  margin-top: -1vh;
}

.imgAssetsEth {
  width: 2.2vw;
  position: absolute;
  margin-top: -1vh;
  margin-left: -3vw;
}

.imgAssetsGold {
  width: 3vw;
  position: absolute;
  margin-top: -0vh;
  margin-left: -3.3vw;
}

.imgAssetsSilver {
  width: 3vw;
  position: absolute;
  margin-top: 0.5vh;
  margin-left: 0vh;
}

.imgCoffresCont {
  text-align: center;
  width: 10vw;
  font-size: 1.5vw;

  border-width: 5px;
  border-style: solid;
  border-radius: 5px;

  margin-left: 2vw;
  margin-right: 2vw;

  float: left;
  margin-top: 2vh;

  background-color: #2b3149;
}

.imgCoffresParent {
  width: 100%;
  height: 30vh;

  padding-left: 8vw;
}

.imgCoffres {
  width: 8vw;
}

.expLigues {
  width: 84%;
  margin-left: 15%;

  font-size: 1.5vw;
}

.expColone {
  width: 12vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  font-size: 1.5vw;
  float: left;
  text-align: center;
}

.expColoneOr {
  width: 12vw;
  margin-left: 1vw;
  margin-right: 0.5vw;
  font-size: 1.5vw;
  float: left;
  text-align: center;
}

.expImage1 {
  width: 100%;
  margin-bottom: 12%;
}

.expImage2 {
  width: 100%;
  margin-bottom: 12%;
}

.expImage3 {
  width: 96%;
  margin-bottom: 8%;
}

.expImage {
  width: 100%;
  margin-bottom: 12%;
}

.blocExp {
  color: white;
  font-style: oblique;
  font-size: 1.5vw;
  margin-top: 5%;
}

.ligneFull {
  height: 2px;
  width: 93%;
  background-color: white;
}

.leftSection {
  border-right-width: 3px;
  border-right-style: solid;

  margin-top: 17vh;
  padding-left: 5vw;
  float: left;
  width: 50%;
  height: 60vh;
}

.rightSection {
  /* border-width:3px;
    border-style:solid;*/

  margin-top: 17vh;
  padding-top: 3vh;
  height: 60vh;
}

.leftIG {
  height: 75%;
  float: left;
  padding-left: 1vw;
}

.Alert {
  position: absolute;
  color: white;
  width: 25vw;
  margin-left: 2vw;
  margin-top: 65vh;
}
.rightIG {
  height: 75%;
  width: 9 vw;
  float: right;
  padding-right: 1vw;
}

.crypto {
  width: 10vw;
  height: 7vh;

  text-align: center;
  vertical-align: middle;
  line-height: 6vh;

  margin: 2vw;
  margin-left: 17vw;

  background-color: #283f55;

  font-size: 1.4vw;
  font-weight: bold;

  border-radius: 15%;
  border-width: 4px;
  border-style: solid;
  border-color: white;
}

.twentyfor {
  width: 10vw;
  height: 7vh;

  text-align: center;
  vertical-align: middle;
  line-height: 6vh;

  margin: 2vw;
  margin-left: 17vw;

  background-color: #283f55;

  font-size: 1.4vw;
  font-weight: bold;

  border-radius: 15%;
  border-width: 4px;
  border-style: solid;
  border-color: white;
}

.butrank {
  background-color: #283f55;
  color: white;
  height: 8vh;
  width: 10vw;

  display: block;
  margin-left: 69.5vw;
  margin-bottom: 2vh;
  margin-top: 3vh;

  text-align: center;
  vertical-align: middle;
  line-height: 7vh;

  font-size: 1.4vw;

  border-radius: 15%;
  border-width: 3px;
  border-style: solid;
  border-top-color: red;
  border-right-color: red;
  border-left-color: green;
  border-bottom-color: green;
}

.butRes {
  float: right;
  position: relative;
  height: auto;
}

.flecheHaut {
  width: 2.4vw;
  cursor: pointer;
}

.flecheBas {
  width: 2vw;
  cursor: pointer;
  position: absolute;
  margin-left: -2.3vw;
}
.rankingBis {
  background-color: #283f55;
  border-radius: 10px;
  width: 40vw;
  height: 19vh;
  margin-left: 55vw;
  margin-top: 4vh;
  padding-top: 3vh;
  padding-left: 1.3vw;

  text-align-last: left;
}

.podium {
  background-color: black;
  padding-top: 1vh;
  padding-bottom: 1vh;
  padding-left: 1.5vh;
  padding-right: 1.2vh;

  line-height: 4.5vh;

  border-radius: 5px;
}

.cadreRank {
  border-radius: 5px;
  border-width: 3px;
  border-style: solid;
  border-color: white;
  margin-left: 1vw;
  padding-left: 1vw;
  height: 3.5vh;
  width: 30vw;
  display: inline-block;
}

.rankDiv {
  /*
    border-width:3px;
    border-style:solid;
    border-color:white;*/

  width: 9vw;
  display: inline-block;
}

/********************************************************HOME*********************************************************/
/********************************************************TOURNA*********************************************************/

.floatLeftIG {
  float: left;
  font-size: 0.9vw;
  width: 70%;
}

.floatLeftIG2 {
  float: left;
  font-size: 0.9vw;
  width: 60%;
}


.floatLeftIGLeverage0
{
    float: left;
    font-size: 0.9vw;
}


.floatLeftIG2
{
    float: left;
    font-size: 0.9vw;
    width: 60%;
}


.floatLeftIG5
{
    float: left;
    font-size: 0.9vw;
    width: 30%;
}


.floatLeftIG3
{
    float: left;
    font-size: 0.9vw;
    width: 100%;
    position: absolute;
    top: 6%;
    right: 24%;
}

.leftPanel {
  padding-top: 0vh;
  position: relative;
}

.ingameLeftPanel {
  margin-top: 2%;
  margin-left: 1.8%;
  margin-right: 1.8%;
  width: 18%;
  height: 87vh;
  float: left;
}

.graphPairSelector {
  width: 75%;
  border-radius: 15px;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  padding-left: 15%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 1vw;
  color: white;
  font-weight: bolder;
  margin-bottom: 2%;
  cursor: pointer;
}

.butSelPairVisu {
  width: 75%;
  border-radius: 15px;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  padding-left: 15%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 5%;
  font-size: 1vw;
  color: white;
  font-weight: bolder;
  margin-bottom: 2%;
  cursor: pointer;
  height: 9.5vh;
}

.resInGame {
  border-radius: 15px;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  white-space: pre-line;

  font-size: 0.8vw;
  font-weight: bolder;

  padding-top: 5%;

  padding-left: 15%;

  float: left;

  height: 60.5%;
  width: 85%;

  color: white;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 4%;
}

.TitreIG {
  font-size: 1.1vw;
}

.scoreColor {
  width: 60%;
  display: inline-block;
}

.scoreColorOmega {
  color: rgb(0, 204, 85);
  width: 60%;
  display: inline-block;
}
.scoreColorGamma {
  color: rgb(255, 56, 74);
  width: 60%;
  display: inline-block;
}
.scoreColorDelta {
  color: rgb(231, 206, 74);
  width: 60%;
  display: inline-block;
}
.scoreColorThêta {
  color: #4da6ff;
  width: 60%;
  display: inline-block;
}

.positionClassement {
  display: inline-block;
}

.gifHomeDiscord3 {
  position: absolute;
  top: 16.7%;
  left: 25.75%;
  cursor: pointer;
}

.saveChart {
  display: none;
  text-align: center;
  position: absolute;
  width: 25%;
  top: 20%;
  left: 40%;
  background-color: #28303e;
  font-size: 1.2vw;
  padding-top: 2%;
  padding-bottom: 2%;
  color: white;
  border-style: none;
  border-radius: 15px;
}

.inputSaveChart {
  width: 80%;
  margin-top: 10%;
  margin-bottom: 10%;
  font-size: 1.1vw;
}

.deleteChart {
  display: none;
  text-align: center;
  position: absolute;
  width: 25%;
  top: 20%;
  left: 40%;
  background-color: #28303e;
  font-size: 1.2vw;
  padding-top: 2%;
  padding-bottom: 2%;
  color: white;
  border-style: none;
  border-radius: 15px;
}

.loadChart {
  display: none;
  text-align: center;
  position: absolute;
  width: 25%;
  top: 20%;
  left: 40%;
  background-color: #28303e;
  font-size: 1.2vw;
  padding-top: 2%;
  padding-bottom: 2%;
  color: white;
  border-style: none;
  border-radius: 15px;
}

.clickableChartLoadCont {
}

.saveloadCloser {
  position: absolute;
  top: 2%;
  right: 2%;
  border-style: none;
  background-color: #28303e;
  color: indianred;
  font-size: 1.3vw;
  cursor: pointer;
}

.saveloadCloser:hover {
  color: red;
  font-size: 1.35vw;
}

.chatSelector
{
    color: white;
    font-size: 1vw;
    margin-left: 5%;
    margin-right: 5%;
    cursor: pointer;
}


.chatSelectorCont
{
box-shadow: 0 0 10px 4px black;
background: #1e3736;
padding-top: 0.5vh;
padding-bottom: 0.5vh;
margin-left: 1vw;
border-top-left-radius: 5px;
border-top-right-radius: 5px;
position: relative;
margin-top: 2vh;
z-index: 0;

}




.clickableChartLoad {
  cursor: pointer;
}

.clickableChartLoad:hover {
  color: indianred;

}

.inputDeleteChart {
  width: 80%;
  margin-top: 10%;
  margin-bottom: 10%;
  font-size: 1.1vw;
}

.needloged {
  width: 100%;
  margin-top: 10%;
  font-size: 1.1vw;
  text-align: center;
  color: white;
}

.contentListPP {
  width: 106%;
  display: inline-block;

  padding-top: 1%;
}

.contentListPPDelimitor {
  height: 2px;
  width: 90%;
  background-color: aquamarine;
  margin-bottom: 5%;
  margin-left: 0%;
}

.blocPlayerRes {
  display: inline-block;
  width: 93%;
}


.logs
{
    margin-top: 1%;
    width: 90%;
    height: 29vh;
    padding-left: 10%;
    background: linear-gradient(180deg, #1e3736, #1b1d23);
    box-shadow: 0 0 10px 4px black;
    border-radius: 15px;
    color: white;
    white-space: pre-line;
    font-size: 0.75vw;
    overflow-y: auto;
    display: none;
    position: relative;
    z-index: 2;

}

.blocPlayerPsd {
  cursor: pointer;
  text-align: center;
  width: 60%;
  display: inline-block;
  font-weight: bolder;
  font-size: 0.75vw;
}

.enPos {
  position: relative;
  width: 1vw;
  float: right;
  margin-right: 3%;
}

.rightTT {
  text-align: right;
  display: inline-block;
  width: 28%;
}

.cashpriceCell {
  color: #d8d357;
  margin-left: 12%;
}

.chatSelector {
  color: white;
  font-size: 1vw;
  margin-left: 10%;
  cursor: pointer;
}

.chat {
  font-size: .7vw;
  width: 100%;
  height: 85%;
  color: #fff;
  border-radius: 5px;
  overflow-y: auto;
}


.containerChat
{

    margin-top: 1%;
    width: 90%;
    height: 29vh;
    padding-left: 1%;
    background: linear-gradient(180deg, #1e3736, #1b1d23);
    box-shadow: 0 0 10px 4px black;
    border-radius: 15px;
    position: relative;
    z-index: 2;
}

.tradingView
{
    
     width: 76.8vw;
     float: left;
     height: 54.5vh;

     box-shadow: 0 0 10px 4px black;
     margin-left: 0.2%;
     margin-top: 2%;
     border-radius: 5px;

}

#inputChat {
  width: 9.2vw;
  background-color: #ffffff00;
  color: white;
}

.butEnvoyer {
  background-color: aquamarine;
  border-style: none;
  border-radius: 10px;
  color: black;
  margin-left: 2%;
  width: 3vw;
  font-size: 0.8vw;
  cursor: pointer;
}

.butShare {
  background-color: dodgerblue;
  border-style: none;
  border-radius: 10px;
  color: black;
  margin-left: 1%;
  width: 2.5vw;
  font-size: 0.6vw;
  padding-top: 0.4vh;
  padding-bottom: 0.4vh;
  cursor: pointer;
}

.alertmsg {
  color: red;
}


.bottomPanelIG
{
    height: 29.5vh;
    width: 77%;
    float: left;
    margin-top: 2%;
}

.setGroupe {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(30, 36, 47, 0.9);
  width: 100%;
  height: 100%;
  white-space: pre-line;
}

.setGroupeInside {
  width: 40%;
  margin-left: 32.5%;

  margin-top: 5%;
  color: white;
  font-size: 1.4vw;
}

.closeGroupSelector {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 5%;
  margin-right: 5%;
  border-style: none;
  background-color: rgba(255, 255, 255, 0);
  color: red;
  font-size: 1.2vw;
  cursor: pointer;
}

.groupbtn {
}

.groupeEmeraude {
  background: rgba(0, 204, 85, 0.88);
  box-shadow: 0px 4px 20px rgba(0, 204, 85, 1);
  padding: 1%;
  border-radius: 10px;
  border-style: none;
  width: 51%;
  font-size: 1.2vw;
  cursor: pointer;
  margin-left: 10%;
}

.groupeRubis {
  background: rgba(255, 56, 74, 0.85);
  box-shadow: 0px 4px 20px rgba(255, 56, 74, 1);
  padding: 1%;
  border-radius: 10px;
  border-style: none;
  width: 51%;
  font-size: 1.2vw;
  cursor: pointer;
  margin-left: 10%;
}
.groupeOr {
  background: rgba(231, 206, 74, 0.85);
  box-shadow: 0px 4px 20px rgba(231, 206, 74, 1);
  padding: 1%;
  border-radius: 10px;
  border-style: none;
  width: 51%;
  font-size: 1.2vw;
  cursor: pointer;
  margin-left: 10%;
}
.groupeSaphir {
  background: rgba(0, 51, 204, 0.85);
  box-shadow: 0px 4px 20px rgba(0, 51, 204, 1);
  padding: 1%;
  border-radius: 10px;
  border-style: none;
  width: 51%;
  font-size: 1.2vw;
  cursor: pointer;
  margin-left: 10%;
}

.colorEm {
  color: rgb(0, 204, 85);
}
.colorRubis {
  color: rgb(255, 56, 74);
}
.colorOr {
  color: rgb(231, 206, 74);
}
.colorSaphir {
  color: #4da6ff;
}

.tradingView {
  width: 75vw;
  float: left;
  height: 55vh;

  box-shadow: 0 0 10px 4px black;
  margin-left: 0.2%;
  margin-top: 2%;
}

.layout__area--top {
  background-color: red;
}

.bottomPanelIG {
  height: 29vh;
  width: 77%;
  float: left;
  margin-top: 2%;
}

.bottomLeftPanelIG {
  height: 100%;
  width: 35%;
  float: left;
  background-color: green;
  border-radius: 15px;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
}

.bottomLeftPanelIGleft {
  width: 63%;
  height: 100%;
  margin-top: 2%;
  float: left;
}

.pairSelectorPro {
  font-size: 1.3vw;
  color: white;
  text-align: center;
  font-weight: bolder;
  cursor: pointer;
}
  /*
  margin-top: -47vh;
  */
.butSelPairSpan {
  margin-left: 17.5%;
  margin-top: -3vh;
  padding-left: 0%;

  color: white;

  z-index: 10;
  position: absolute;
  display: inline-block;

  background: linear-gradient(115.95deg, #1e242f 100%, #0d1215 100%);

  font-size: 1.2vw;
  width: 9%;

  border-radius: 10px;
}

.butSelPairSpanS {
  width: 7%;
  margin-left: 13%;
  z-index: 10;
  position: absolute;
  display: block;
  text-align: center;

  margin-top: 0.5%;
  padding-bottom: 0%;
  font-size: 1vw;

  background: linear-gradient(115.95deg, #1e242f 100%, #0d1215 100%);
  border-radius: 7px;
}

.butSelPair {
  padding-left: 1vw;
  padding-right: 1vw;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  background-color: inherit;
  font-size: 1vw;
  color: white;
  pointer: cursor;
  margin-right: 5%;
  width: 100%;
  border-radius: 6px;
  border-style: none;
}

.butSelPair:hover {
  background-color: #ff5757;
}

.hoverRed:hover {
  background-color: #ff5757;
}

.butSelPair:hover {
  cursor: pointer;
}

.appleSelector {
  width: 14%;
  border-radius: 20px;
  border-width: 1px;
  border-color: lightgray;
  border-style: solid;
}

.appleSelectorIn {
  background-color: lightgray;
  border-radius: 20px;
  width: 50%;
  display: block;
}

.bottomLeftPanelIGright {
  width: 32%;
  height: 91%;
  margin-top: 2%;
  margin-left: 1%;
  float: left;
}


.bottomRightPanelIG
{
    height: 89%;
    padding-top: 2%;
    width: 62%;
    margin-left: 3%;
    float: left;
    background-color: green;
    border-radius: 15px;
    background: linear-gradient(180deg, #1e3736, #1b1d23);
    box-shadow: 0 0 10px 4px black;
    overflow-y: auto;
}

.bottomRightPanelIG2 {
  height: 100%;
  width: 60%;
  margin-left: 3%;
  padding-left: 2%;
  float: left;
  background-color: green;
  border-radius: 15px;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  overflow-y: auto;
  color: white;
}

.addOrder {
  border-right-style: solid;
  border-right-color: white;
  height: 90%;
  width: 55%;
  float: left;
  margin-top: 1%;
  font-size: 0.8vw;
}

.ordresPairs {
  float: left;
  width: 33%;
}

.ordresPair {
  width: 75%;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.8vw;
  margin-bottom: 1%;
  background-color: inherit;
  color: white;
  border-radius: 6px;
  cursor: pointer;
}
.ordresPair:hover {
  border-color: #ff5757;
}

.ordresActions {
  float: left;
  width: 33%;
  margin-top: 5%;
}

.ordresAction {
  width: 75%;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.8vw;
  margin-bottom: 1%;
  background-color: inherit;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 6%;
}

.ordresAction:hover {
  border-color: #ff5757;
}

.valueActions {
  float: left;
  width: 33%;
  margin-top: 12%;
}

.valueAction {
  background-color: #ffffff00;
  font-size: 0.8vw;
  color: white;
  width: 60%;
}

.validAction {
  width: 60%;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.8vw;
  margin-bottom: 1%;
  background-color: inherit;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10%;
  border-style: none;
  background-color: #ff5757;
}

.displayedOrders {
  padding-left: 5%;
  width: 88%;
  height: 90%;
  float: left;
  font-size: 1.1vw;
  overflow-y: auto;
}

.contInputComplex {
  text-align: center;
  font-size: 0.9vw;
  color: white;
}








.displayedOrders
{
    padding-left: 2%;
   width:95%;
   height: 90%;
   float: left;
   font-size: 1.1vw;
   overflow-y: auto;
}

.slider {
  margin-top: 4%;
  width: 84%;
  margin-left: 8%;
}

.inputSlider {
  border-radius: 7px;

  color: #181c29;

  padding: 0.2vw;
  background-color: white;

  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 1.5vw;

  text-align: right;
  font-size: 1.2vw;
}

.inputSlider2 {
  border-radius: 7px;

  color: #181c29;

  padding: 0.2vw;
  background-color: white;

  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 5vw;

  text-align: right;
  font-size: 1.2vw;
}

.buyerContainer {
  width: 100%;
  margin-top: 5%;
  height: 8vh;
}

.buyMode {
  color: black;
  width: 48%;
  height: 100%;
  float: left;
  border-radius: 7px;
  background-color: #11d89c;
  font-size: 0.9vw;
  cursor: pointer;
  border: none;
  font-weight: 900;
}

.sellMode {
  float: left;
  color: black;
  width: 48%;
  height: 100%;
  margin-left: 2%;
  border-radius: 7px;
  background-color: #ff5757;
  cursor: pointer;
  font-size: 0.9vw;
  border: none;
  font-weight: 900;
}

.toutcloturer {
  position: absolute;
  margin-left: 1%;
  margin-top: -2vh;
  padding: 0.3%;
  color: black;
  background-color: #ff5757;
  border-style: none;
  border-radius: 10px;
  font-size: 0.72vw;
}

.bottomInfos {
  width: 100%;
  margin-top: 11%;
  color: white;
}

.timeLeft {
  position: absolute;
  top: 6vh;
  right: 11%;
}

.inputBuy {
  width: 46.5%;
  margin-top: 2%;
  border-style: none;
  border-radius: 5px;
  text-align: center;
  background-color: #11d89c;
}

.inputSell {
  margin-left: 2%;
  width: 46.5%;
  border-style: none;
  border-radius: 5px;
  text-align: center;
  background-color: #ff5757;
}

.inputT {
  width: 40%;

  color: black;

  border: none;
  font-size: 0.9vw;
}

.percentSlider {
  color: white;

  color: white;

  font-size: 1vw;
  border-radius: 7px;
  margin-left: 45%;
  margin-top: 2%;
}

.prixEntree {
  background-color: red;
  width: 100%;
  color: white;
  margin-top: 4%;
}

.comLevierToggle {
  width: 80%;
  margin-top: 5%;
  margin-left: 10%;
  font-size: 0.9vw;

  text-align: center;
}

.sliderLevier {
  width: 80%;
}

.levierUnder {
  font-size: 1vw;
  color: white;
  width: 100%;
  display: inline-block;
  margin-bottom: 2%;
}

.ingameLevier {
  color: aquamarine;
  font-size: 1.1vw;
  margin-left: 0%;
  margin-top: -1vh;
}

.inputLevier {
  width: 2vw;
  margin-left: 30%;
  margin-right: 0%;
  float: left;
  color: white;
  background-color: #ffffff00;

  border: none;
  font-size: 1.1vw;
}

.iconLevier {
  width: 50%;
  margin-left: 2;
  margin-bottom: 10%;
}


.levierUnder
{
    font-size: 1vw;
    color: white;
    width: 100%;
     display: inline-block;
     margin-bottom: 7%;
}

.ingameLevier
{
    color: aquamarine;
    font-size: 1.1vw;
    margin-left: 0%;
    margin-top: -1vh;
}


.inputLevier
{
   
     width: 2vw;
    margin-left: 30%;
    margin-right: 0%;
    float: left;
    color: white;
    background-color: #FFFFFF00;

   
    border:none;
    font-size: 1.1vw;
}

.iconLevier
{
    width: 50%;
    margin-left: 2;
    margin-bottom: 10%;
    
}

.validLevier
{
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    
    color: white;
    background: linear-gradient(180deg, #1e3736, #1b1d23);
    box-shadow: 0 0 10px 4px black;
    border-style: none;
    border-radius: 5px;

    margin-top: 1%;

    cursor: pointer;

}

.openOrders {
  float: left;
  width: 80%;
}

.validLevier2 {
  padding-top: 0.4vh;
  padding-bottom: 0.3vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;

  color: white;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  border-style: none;
  border-radius: 5px;

  cursor: pointer;

  margin-left: 2vw;
  margin-right: 2vw;

  float: right;
}

.warning {
}

.BlocPosition {
  height: 80%;
  width: 100%;
  margin-bottom: 5%;
}

.BlocPositionGauche {
  height: 100%;
  width: 18%;
  float: left;
  border-right-style: solid;
  border-right-color: lightgray;
  border-right-width: 2px;
}



.spacerPositionsInfosLeft {
  width: 51%;
  float: left;
}

.BlocPositionLevier {
  color: aquamarine;
  font-size: 1.2vw;
}

.BlocPositionCentre {
  height: 100%;
  width: 32%;
  margin-left: 1%;
  float: left;
  border-right-style: solid;
  border-right-color: lightgray;
  border-right-width: 2px;
  color: white;
  padding-left: 3%;
  padding-right: 3%;
  font-size: 1vw;
}

.BlocPositionDroite {
  height: 100%;
  width: 38%;
  margin-left: 0%;
  float: left;
  border-right-style: solid;
  border-right-color: lightgray;
  border-right-width: 2px;
}

.cloturer {
  margin-top: 1vh;
  border-radius: 10px;
  background-color: #ff5757;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;

  border-style: none;

  padding-left: 1vw;
  padding-right: 1vw;
}

.sharePosition {
  margin-top: 1vh;
  border-radius: 10px;
  background-color: deepskyblue;
  text-align: center;
  font-size: 1vw;
  cursor: pointer;

  border-style: none;

  padding-left: 0.8vw;
  padding-right: 0.8vw;
  margin-top: 2.5vh;
}

.autoClot {
  float: left;
  font-size: 1vw;
  text-align: left;
  color: white;
  line-height: 2.3vh;
  margin-left: 8%;
  margin-top: 0%;
  width: 87%;
}

.BlocPositionPaire
{
   color: white;
   font-size: 1.3vw;
   font-weight: bolder;
   border-left-style: solid;
    border-left-color: aquamarine;
    border-left-width: 2px;
    padding-left: 2%;
    margin-left: 10%;
    text-align: center;
    margin-top: 10%;

}


.BlocPositionPaireShort
{
   color: white;
   font-size: 1.3vw;
   font-weight: bolder;
   border-left-style: solid;
    border-left-color: #ff5757;
    border-left-width: 2px;
    padding-left: 2%;
    margin-left: 10%;
    margin-top: 10%;

}



.BlocPositionLevier
{
   color: aquamarine;
   font-size: 1.2vw;
}

.BlocPositionLevierShort
{
   color: #ff5757;
   font-size: 1.2vw;
}

  

.inputSLTPBrut {
  width: 3vw;
  background-color: #2b3149;
  border-style: none;
  color: white;

  font-size: 1vw;
}

.btSLTP {
  height: 0vh;
  width: 0vw;
  margin-left: 0%;
  margin-top: -2vh;
  border-style: none;
  float: right;
}

.btSLTP4 {
  height: 2vh;
  width: 1.2vw;
  border-style: none;
  margin-left: 0.5vw;
  background-color: #ffffff00;
  font-size: 0.8vw;
  cursor: pointer;
}

.imgValidSL {
  width: 2vw;
}

.inputSLTPLarge {
  width: 4.5vw;
  margin-right: 0vw;
  font-size: 0.8vw;
  background-color: #2b3149;
  border-style: none;
  color: white;
  text-align: right;
}

.inputSLTP {
  width: 2.6vw;
  font-size: 0.8vw;
  background-color: #2b3149;
  border-style: none;
  color: white;
  margin-left: 0.5vw;
  text-align: right;
}

.spacerPositionsInfosLeft {
  float: left;
}

.spacerPositionsInfosRight {
  float: right;
}

.floatRightIG {
  float: right;
  font-size: 0.9vw;
}

.btSLTP2 {
  height: 0vh;
  width: 0vw;
  margin-left: 5%;
  margin-top: 5%;
  background-color: grey;
  border-radius: 5px;
  color: white;
  float: left;
  border-style: none;
}

.gameTimer {
  position: absolute;
  padding-left: 0.2vw;
  width: 10vw;
  margin-left: 3.5vw;
  margin-top: -4vh;
  padding-top: 0.6vh;
  padding-bottom: 0.6vh;
  color: white;
  border-style: solid;
  border-width: 3px;
  border-color: white;
  border-radius: 5px;
  background-color: #2b3149;
  font-size: 0.9vw;
  height: 4.9vh;
  white-space: pre-line;
  text-align: center;
}

.ingameInfos {
  border-right-color: grey;
  border-right-style: solid;
  border-right-width: 1px;

  border-left-color: grey;
  border-left-style: solid;
  border-left-width: 1px;

  font-size: 1vw;
  float: left;
  width: 22vw;
  height: 19.5vh;

  margin-left: 2vw;
  margin-top: -1.7vw;

  line-height: 2vh;
}

.scoreGameR {
  text-align: center;
  padding-left: 0vw;
  width: 10vw;
  margin-left: 0vw;
  margin-top: 0.5vh;
  padding-top: 3vh;
  padding-bottom: 3vh;
  color: white;
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: grey;
  font-size: 1vw;
  white-space: pre-line;
  display: inline-block;
  float: left;
}
.tournamentContainer {
  float: left;
  background-color: white;
  border-radius: 10px;

  margin-top: 5vh;
  padding-top: 3vh;
  padding-bottom: 3vh;
  margin-left: 5vw;
  padding-left: 5vw;
  padding-right: 5vw;
  width: 20vw;
  font-size: 1vw;
}
.tournamentContainerLeft {
  float: left;
  background-color: white;
  border-radius: 10px;

  margin-top: 5vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-left: 15vw;
  padding-left: 2vw;
  padding-right: 2vw;
  width: 10vw;
  font-size: 1vw;
}
.tournamentContainerRight {
  float: left;
  background-color: #08664a;
  border-radius: 10px;

  margin-top: 5vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-left: 5vw;
  padding-left: 3vw;
  padding-right: 3vw;
  width: 12vw;
  font-size: 1vw;
}
.tournamentContainerRight2 {
  float: left;
  background-color: #5271ff;
  border-radius: 10px;

  margin-top: 5vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-left: 5vw;
  padding-left: 3vw;
  padding-right: 3vw;
  width: 12vw;
  font-size: 1vw;
}
.tournamentContainerRight3 {
  float: left;
  background-color: #ff7a7a;
  border-radius: 10px;

  margin-top: 5vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-left: 5vw;
  padding-left: 3vw;
  padding-right: 3vw;
  width: 12vw;
  font-size: 1vw;
}
.tournamentContainerCenter {
  white-space: pre-line;
}

.tournamentContainerRight4 {
  float: left;
  background-color: blueviolet;
  border-radius: 10px;

  margin-top: 5vh;
  padding-top: 1vh;
  padding-bottom: 1vh;
  margin-left: 5vw;
  padding-left: 3vw;
  padding-right: 3vw;
  width: 12vw;
  font-size: 1vw;
}

.sponsorTNT {
  position: absolute;
  margin-top: -4vh;
  margin-left: 2vw;

  color: white;
}

.contForgot {
  text-align: center;
  color: white;
  margin-top: 5%;
  margin-bottom: 4%;
}

.generalControl {
  float: left;
  border-style: solid;
  border-color: white;
  border-width: 1px;

  height: 30vh;
  padding-right: 2vw;

  margin-top: 0vh;

  margin-left: 2vw;
}

.backupList {
  position: absolute;
  top: 49vh;
  left: 28vw;
  border-style: solid;
  border-color: white;
  border-width: 1px;

  height: 30vh;
  width: 8.5vw;
  padding-left: 1.8vw;

  margin-left: 2vw;

  overflow-y: auto;
}

.resumeTournois {
  border-style: solid;
  border-color: white;
  border-width: 1px;

  overflow-y: auto;

  float: left;

  margin-left: 2vw;
  width: 10vw;
  padding-left: 1vw;
  height: 80vh;
  padding-top: 1vh;
}
.effect {
  font-size: 0.9vw;
  margin-top: 1vh;
  margin-left: 25vw;
  display: block;
  position: absolute;
  color: white;
}

.bandeauIngame {
  position: absolute;
  color: white;
  margin-top: -1.5vh;
  margin-left: 35vw;
}

.firstControls {
  border-style: solid;
  border-color: white;
  border-width: 1px;
  height: 75vh;
  width: 25vw;
  padding-top: 3vh;
  padding-left: 1vw;
  float: left;
}

.setsoldecss {
  border-style: solid;
  border-color: white;
  border-width: 1px;
  height: 10vh;
  padding-top: 1vh;
  padding-left: 1vw;
  float: left;
}
.tournamentContainerCenter {
  white-space: pre-line;

  float: left;
  background-color: #11d89c;
  border-radius: 10px;

  margin-top: 5vh;
  margin-left: 36vw;
  width: 25vw;
  height: 24vh;
  font-size: 1vw;

  padding-top: 2vh;
  padding-left: 1vw;
}

.tournamentContainerCenter2 {
  white-space: pre-line;

  float: left;
  background-color: #5271ff;
  border-radius: 10px;

  margin-top: 5vh;
  margin-left: 36vw;
  width: 25vw;
  height: 20vh;
  font-size: 1vw;

  padding-top: 2vh;
  padding-left: 1vw;
}

.tournamentContainerCenter3 {
  white-space: pre-line;

  float: left;
  border-radius: 10px;

  margin-top: 5vh;
  margin-left: 5%;
  padding-bottom: 2vh;
  width: 25%;
  font-size: 1vw;
  text-align: center;
  padding-top: 1%;
  color: white;

  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
}

.tournamentContainerCenter4 {
  white-space: pre-line;
  color: white;
  float: left;
  border-radius: 10px;

  margin-top: 5vh;
  margin-left: 5%;
  padding-bottom: 2vh;
  width: 25%;
  font-size: 1vw;
  text-align: center;

  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

.bannerEvent {
  width: 60%;
  color: white;
  margin-top: -3vh;
  padding-bottom: 1vh;
  padding-top: 1vh;
  margin-left: 20%;
  font-size: 0.9vw;
  border-radius: 5px;
  margin-bottom: -1vh;

  background: #ff4f5e;
  text-align: center;
  font-weight: bolder;
}

.usages {
  color: red;
  margin-left: 2vw;
  font-size: 1vw;
}

.titreTournoi {
  margin-top: 3%;
  margin-left: 20%;
  width: 60%;
  color: white;
  background: linear-gradient(
    152.97deg,
    rgba(9, 56, 223, 0.5) 0%,
    rgba(255, 255, 255, 0.195) 100%
  );
  text-align: center;
  padding-top: 1vh;
  font-size: 2.1vw;
  font-weight: bolder;
  padding-bottom: 2vh;
  border-radius: 8px;
}

.titreTournoiNormal {
  margin-top: 3%;
  margin-left: 20%;
  width: 60%;
  color: white;
  background: linear-gradient(
    152.97deg,
    rgba(17, 216, 156, 0.5) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
  text-align: center;
  padding-top: 1vh;
  font-size: 2.1vw;
  font-weight: bolder;
  padding-bottom: 2vh;
  border-radius: 8px;
  margin-bottom: 2%;
}

.titreTournoiClasse {
  margin-top: 3%;
  margin-left: 20%;
  width: 60%;
  color: white;

  background: linear-gradient(
    152.97deg,
    rgba(255, 0, 0, 0.2) 0%,
    rgba(255, 255, 255, 0.315) 100%
  );
  text-align: center;
  padding-top: 1vh;
  font-size: 2.1vw;
  font-weight: bolder;
  padding-bottom: 2vh;
  border-radius: 8px;
}
.sousTitreTournoi {
  font-size: 1.1vw;
}

.titreTournois {
  margin-top: 10vh;
  margin-left: 20vw;
  color: white;
}

.sousTournoi {
  margin-left: 5%;
  width: 90%;
  float: left;
  height: 5%;
  border-top-style: solid;
  border-top-width: 1px;
  border-color: lightgray;
  margin-top: 3%;
  padding-top: 2%;
  height: 5%;
  font-size: 0.9vw;
}
.tournois {
  background-color: red;
}

.TVChartContainer {
  width: 100%;
}

.estPrimaire {
  float: left;
  width: 50%;
}



.floatRightIG5
{
    float: right;
    font-size: 1.3vw;
    padding-top: 0.5vh;
}


.btSLTP2
{
    height: 0vh;
    width: 0vw;
    margin-left: 5%;
    margin-top: 5%;
    background-color: grey;
    border-radius: 5px;
    color: white;
    float: left;
    border-style: none;
}

.estSecondaire {
  float: right;
  width: 49%;
  height: 100%;

}
.buttonsTournament {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 80%;

  font-size: 1vw;
  color: white;
  cursor: pointer;

  background: linear-gradient(275.76deg, #ff384a 44.33%, #ff5463 98.56%);
  box-shadow: 0px 0px 15px 5px rgba(255, 56, 74, 0.5);
}



.buttonsTournamentMint {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 80%;

  font-size: 0.8vw;
  color: white;
  cursor: pointer;

  margin-left: 10%;

  background: linear-gradient(275.76deg, #ff384a 44.33%, #ff5463 98.56%);
  box-shadow: 0px 4px 20px rgba(255, 56, 74, 0.5);
}

.buttonsTournamentMintShort {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 25%;
  float: right;

  margin-right: 5%;

  font-size: 0.8vw;
  color: white;
  cursor: pointer;

  margin-left: 10%;

  background: linear-gradient(275.76deg, #ff384a 44.33%, #ff5463 98.56%);
  box-shadow: 0px 4px 20px rgba(255, 56, 74, 0.5);
}

.buttonsTournamentMintShortGrey {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 25%;
  float: right;

  margin-right: 5%;

  font-size: 0.8vw;
  color: white;
  cursor: pointer;

  margin-left: 10%;

  background: linear-gradient(275.76deg, grey 44.33%, grey 98.56%);
}

.buttonsTournamentMintShortStat {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 25%;
  float: right;

  margin-right: 5%;

  font-size: 0.8vw;
  color: white;
  cursor: pointer;

  margin-left: 10%;

  background: linear-gradient(275.76deg, #ff384a 44.33%, #ff5463 98.56%);
  box-shadow: 0px 4px 20px rgba(255, 56, 74, 0.5);
  margin-top: 2.5%;
}

.buttonsTournamentMintShortStat {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 25%;
  float: right;

  margin-right: 5%;

  font-size: 0.8vw;
  color: white;
  cursor: pointer;

  margin-left: 10%;

  background: linear-gradient(275.76deg, #ff384a 44.33%, #ff5463 98.56%);
  box-shadow: 0px 4px 20px rgba(255, 56, 74, 0.5);
  margin-top: 2.5%;
}


.buttonsTournamentMintShortStatGrey {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 25%;
  float: right;

  margin-right: 5%;

  font-size: 0.8vw;
  color: white;
  cursor: pointer;

  margin-left: 10%;

  background: linear-gradient(275.76deg, grey 44.33%, grey 98.56%);
  margin-top: 2.5%;
}



.buttonsTournamentMintGrey {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 80%;

  font-size: 0.8vw;
  color: white;
  cursor: not-allowed;

  margin-left: 10%;

  background: linear-gradient(275.76deg, grey 44.33%, grey 98.56%);
}

.buttonsTournamentMintGreyKey {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 80%;

  font-size: 0.8vw;
  color: white;
  cursor: not-allowed;

  margin-left: 10%;

  background: linear-gradient(275.76deg, grey 44.33%, grey 98.56%);
}

.buttonsTournamentMintKey {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 0.5vh;
  padding-top: 0.5vh;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 80%;

  font-size: 0.8vw;
  color: white;
  cursor: pointer;

  margin-left: 10%;

  background: linear-gradient(275.76deg, #ff384a 44.33%, #ff5463 98.56%);
}



.buttonsTournamentGp {
  background-color: #2b3149;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 30%;

  font-size: 1vw;
  color: white;
  cursor: pointer;

  background: linear-gradient(275.76deg, #ff384a 44.33%, #ff5463 98.56%);
  box-shadow: 0px 4px 20px rgba(255, 56, 74, 0.5);
}

.buttonMetamask {
  background-color: #ffffff;
  border-radius: 25px;
  border-style: none;
  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;
  width: 50%;

  font-size: 1vw;
  color: white;
  cursor: pointer;
  box-shadow: 0px 4px 20px rgba(247, 247, 247, 0.5);
}

.buttonsTournamentShort {
  background-color: #2b3149;
  border-width: 5px;
  border-style: solid;
  border-radius: 5px;
  border-color: white;

  padding-bottom: 1vh;
  padding-top: 1vh;
  padding-left: 1vw;
  padding-right: 1vw;

  position: absolute;
  margin-left: -13.5vw;
  margin-top: 10vh;

  color: white;
  cursor: pointer;
}

.tournaNbJoueurs {
  margin-top: 5%;
  float: left;
  margin-left: 10%;
  width: 80%;

  text-align: center;
}

.tournaDate {
  float: left;
  margin-left: 10%;
  text-align: center;
  margin-top: 3%;
  width: 80%;
}

.assetTour {
  float: left;

  margin-top: 5%;
  margin-left: 10%;
  width: 80%;

  text-align: center;
}

.spanButtonsTournament {
  margin-top: 3vh;
  margin-bottom: 2vh;
  margin-left: 0vw;
  float: left;
}

.spanButtonsTournamentCP {
  margin-top: 5%;
  margin-bottom: 1vh;
  margin-left: 20%;
  float: left;
  width: 60%;
}

.spanButtonsTournamentCP2 {
  margin-top: 1%;
  margin-bottom: 1vh;
  margin-left: 20%;
  float: left;
  width: 60%;
}

.spanButtonsTournamentCPz {
  margin-top: 3vh;
  margin-bottom: 1vh;
  margin-left: -0.6vw;
  float: left;
}

.spanButtonsTournamentD {
  margin-top: 3vh;
  margin-bottom: 3vh;
  margin-left: 0vw;
  float: left;
}

.spanButtonsTournamentDz {
  margin-top: 3vh;
  margin-bottom: 3vh;
  margin-left: -0.6vw;
  float: left;
}

.iconeJoueurs {
  width: 15%;
}

.iconeDuree {
  width: 15%;
  margin-top: 0vh;
  margin-left: 0vw;
}

.iconeDureeD {
  width: 2.5vw;
  margin-top: -0.5vh;
  margin-left: -3.2vw;
  position: absolute;
}

.imgCashPrize {
  width: 15%;
}

.cashPTourna {
  float: left;
  text-align: center;
  margin-left: 10%;
  width: 80%;
  margin-top: 5%;
}

.imgCashPrizeFrise {
  width: 87%;
}

.iconeSettings {
  width: 15%;
}

.eurSellmode {
  width: 5vw;
  height: 2.5vh;
  margin-left: 3.6vw;
  background-color: lightgrey;
  border-radius: 5px;
  border-style: solid;
  border-color: lightgrey;
  align-content: left;
  font-size: 0.9vw;
  padding-left: 1.1vw;
}

/********************************************************TOURNA*********************************************************/
/********************************************************LOGIN**********************************************************/

.loginCont {
  text-align: center;
  background-color: white;
  color: black;

  margin-left: 30vw;
  margin-right: 30vw;
  margin-top: 20vh;
  padding-top: 5vh;
  padding-bottom: 5vh;

  border-radius: 5%;

  font-size: 1.2vw;
}

form {
  margin: 0 auto;
  width: 250px;
  color: black;
}

.inputLog {
  width: 15vw;
}

/******************************************************LOGIN*************************************************************/
/*****************************************************FORGOT PWD********************************************************/

.forgotCont {
  text-align: center;
  color: white;

  margin-left: 20vw;
  margin-right: 20vw;
  margin-top: 20vh;
  padding-top: 5vh;
  padding-bottom: 5vh;

  border-radius: 5%;

  font-size: 1.2vw;
}

/*****************************************************FORGOT PWD********************************************************/

.regCont {
  text-align: center;
  color: white;
  float: left;
  margin-left: 1%;
  margin-top: 1%;
  padding-top: 1%;
  padding-bottom: 1%;
  width: 40%;
  font-size: 1.2vw;
}

.regContLeft {
  float: left;
}

/****TOURNAMENT****/

.butBack {
  height: 6vh;
}

.buysell {
  width: 100%;
}
/********/

/*****TRADINGVIEW****/
.tvw {
  float: left;
  width: 11vw;
  margin-top: 4vh;
}

.TVleft {
  display: block;
  width: 32vw;
  height: 61vh;

  float: left;
}

.TVright {
  width: 50%;
  height: 100%;
  display: block;
  float: right;
}

.TVTopleft {
  display: block;
  width: 38.5vw;
  height: 28vh;
}

.TVBotleft {
  display: block;
  width: 38.5vw;
  height: 27vh;
}

.selecSimple {
  top: 0;
  right: 0;
  float: right;

  color: white;
  padding: 6px;
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  border-radius: 6px;

  margin-right: 9vw;
  margin-top: 10.3vh;
  position: absolute;
  cursor: pointer;

  z-index: 10;
  font-size: 0.9vw;
}

.selecPro1 {
  float: left;

  color: white;
  padding: 4px;
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  border-radius: 6px;

  margin-left: -2.2vw;
  margin-top: 0.5vh;
  position: absolute;
  cursor: pointer;

  z-index: 10;
  font-size: 0.9vw;
}

.selecLong1 {
  margin-left: 29.9vw;
  margin-top: 0.4vh;
}

.selecPro2 {
  float: left;

  color: white;
  padding: 4px;
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  border-radius: 6px;

  margin-left: 36.3vw;
  margin-top: 0.5vh;
  position: absolute;
  cursor: pointer;

  z-index: 10;
  font-size: 0.9vw;
}

.selecLong2 {
  margin-left: 68.3vw;
  margin-top: 0.4vh;
}

.selecPro3 {
  float: left;

  color: white;
  padding: 4px;
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  border-radius: 6px;

  margin-left: -2.2vw;
  margin-top: 28.5vh;
  position: absolute;
  cursor: pointer;

  z-index: 10;
  font-size: 0.9vw;
}

.selecLong3 {
  margin-left: 29.9vw;
  margin-top: 28.5vh;
}

.selecAvance {
  top: 0;
  right: 0;
  float: right;
  cursor: pointer;
  color: white;
  padding: 6px;
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  border-radius: 6px;
  font-size: 0.9vw;

  z-index: 10;
  margin-right: 4.5vw;
  margin-top: 10.3vh;
  position: absolute;
}

.ingameOrders {
  font-size: 1vw;
  white-space: pre-line;
  border-style: solid;
  border-color: grey;
  border-width: 0.1vh;
  margin-right: 3vw;
  margin-top: 14vh;

  line-height: 2.9vh;

  padding: 1.1vw;

  width: 11vw;
  float: right;

  color: white;

  background-color: #181c29;
}

.recapingame {
  border-radius: 8px;
  float: left;
  margin-left: 4.1vw;

  margin-top: 2vh;

  padding: 0.3vw;
  font-size: 1vw;
  color: white;

  width: 7vw;

  text-align: center;
  color: #181c29;
  background-color: white;
  font-weight: bold;
}

.commands {
  color: white;

  margin-left: 1vw;
  margin-top: 1vw;

  height: 20vh;

  font-size: 1.3vw;
}

.buy,
.sell {
  width: 82vw;
  float: left;
  height: 18.5vh;
  position: absolute;
  margin-left: 16vw;
  margin-top: 64.5vh;

  padding-top: 2vh;
  padding-bottom: 1.2vh;

  font-size: 1.3vw;
}

.buyBut {
  cursor: pointer;
  margin-left: 13.4vw;
  margin-top: 3vh;

  border-radius: 5px;
  border-color: green;
  color: black;
  background-color: lightgreen;

  width: 8vw;

  padding: 0.3vw;
}

.goSellBut {
  cursor: pointer;
  margin-left: 32vw;
  margin-top: 3vh;
  width: 5.8vw;
  border-radius: 5px;
  color: lightcoral;
  background-color: lightgray;
  border-color: lightgray;
  padding: 0.3vw;
  font-size: 0.8vw;
}

.sellBut {
  cursor: pointer;
  margin-left: 13.7vw;
  margin-top: 1vh;

  border-radius: 5px;
  border-color: green;
  color: black;
  background-color: lightcoral;

  width: 7vw;

  padding: 0.3vw;
}

.goBuyBut {
  cursor: pointer;
  margin-left: 1vw;
  margin-top: 1vh;
  width: 6vw;
  border-radius: 5px;
  border-color: lightgray;
  color: green;
  background-color: lightgray;
  font-size: 0.8vw;
  padding: 0.3vw;
}

.prix {
  border-radius: 5px;
  float: left;
  color: black;
  background-color: lightgray;
  width: 8vw;
  margin-left: 13.5vw;
  margin-bottom: 2.5vh;
}

.solde {
  border-radius: 5px;
  color: black;
  background-color: lightgray;
  width: 6.49vw;
  margin-left: 31.6vw;
  padding-top: 0.2vh;
  padding-bottom: 0.2vh;
  margin-bottom: 2.5vh;
}

.solde2 {
  border-radius: 5px;
  color: black;
  background-color: lightgray;
  width: 10vw;
  margin-left: 28.8vw;
  padding-top: 0.2vh;
  padding-bottom: 0.2vh;
  margin-bottom: 2.5vh;
}

.input {
  width: 4vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  margin-left: 1.5vw;
  color: black;

  border: none;
  font-size: 0.9vw;
}

.inputInfos {
  width: 15vw;
  padding: 10px;
  color: white;
  background-color: #ffffff00;
  border-radius: 7px;
}

.inputInfosReferal {
  width: 15vw;
  padding: 10px;
  color: white;
  background-color: #ffffff00;
  border-radius: 7px;
}

.formRegis {
  margin-left: 0%;
}

.comLevier {
  display: none;
  width: 13vw;
  margin-top: 77vh;
  margin-left: 49.5vw;
  height: 17vh;
  font-size: 0.8vw;

  position: absolute;

  background-color: #1c202e;

  z-index: 20;

  top: 0;
  left: 0;
}

.indicLevier {
  color: white;
  font-size: 1vw;
  margin-left: 2.8vw;
}

.bottomRightSelector {
  margin-top: -3vh;
  margin-left: 5vw;
  color: white;
  font-size: 1vw;
  padding-left: 8px;
  background: #1e3736;
  box-shadow: 0 0 10px 4px black;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  float: left;
  padding-bottom: 0.5vh;
}


/********************************************************TOURNA*********************************************************/
/********************************************************LOGIN**********************************************************/




.loginCont
{
    text-align: center;
    background-color: white;
    color: black;
   
    margin-left: 30vw;
    margin-right: 30vw;
    margin-top: 20vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    
    border-radius: 5%;
    
    font-size: 1.2vw;
}

form
{
    margin: 0 auto;
    width:250px;
    color: black;
}


.inputLog
{
    width: 15vw;
}


/******************************************************LOGIN*************************************************************/
/*****************************************************FORGOT PWD********************************************************/

.forgotCont
{
    text-align: center;
    color: white;
   
    margin-left: 20vw;
    margin-right: 20vw;
    margin-top: 20vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    
    border-radius: 5%;
    
    font-size: 1.2vw;
}

/*****************************************************FORGOT PWD********************************************************/


.regCont
{
    text-align: center;
    color: white;
    float: left;
    margin-left: 1%;
    margin-top: 1%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    width: 40%;
    
    
    font-size: 1.2vw;
}

.regContLeft
{
    float: left;
}


/****TOURNAMENT****/

.butBack
{
    height: 6vh;
}


.buysell
{
     width: 100%;
}
/********/



/*****TRADINGVIEW****/
.tvw
{
    float: left;
     width: 11vw; 
    margin-top: 4vh;
}



.TVleft
{
    display: block;
    width: 32vw;
    height: 61vh;

    float: left;
    
}


.TVright
{
width: 50%;
height: 100%;
display: block;
float: right;

}


.TVTopleft
{
display: block;
width: 38.5vw;
height: 28vh;

}

.TVBotleft
{
display: block;
width: 38.5vw;
height: 27vh;

}

.selecSimple
{
    top: 0;
    right: 0;
    float: right;
    
color: white;
    padding: 6px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

    border-radius: 6px;

    margin-right: 9vw;
    margin-top: 10.3vh;
    position: absolute;
    cursor: pointer;

    z-index: 10;
    font-size: 0.9vw;
}


.selecPro1
{
    
    float: left;
    
    color: white;
    padding: 4px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

    border-radius: 6px;

    margin-left: -2.2vw;
    margin-top: 0.5vh;
    position: absolute;
    cursor: pointer;

    z-index: 10;
    font-size: 0.9vw;
}

.selecLong1
{
    margin-left: 29.9vw;
    margin-top: 0.4vh;

}

.selecPro2
{
    
   float: left;
    
    color: white;
    padding: 4px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

    border-radius: 6px;

    margin-left: 36.3vw;
    margin-top: 0.5vh;
    position: absolute;
    cursor: pointer;

    z-index: 10;
    font-size: 0.9vw;
}

.selecLong2
{
    margin-left: 68.3vw;
    margin-top: 0.4vh;
    
}

.selecPro3
{
    
   float: left;
    
    color: white;
    padding: 4px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

    border-radius: 6px;

    margin-left: -2.2vw;
    margin-top: 28.5vh;
    position: absolute;
    cursor: pointer;

    z-index: 10;
    font-size: 0.9vw;
}

.selecLong3
{
    margin-left: 29.9vw;
    margin-top: 28.5vh;
    
}

.selecAvance
{
    top: 0;
    right: 0;
    float: right;
    cursor: pointer;
    color: white;
    padding: 6px;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);

    border-radius: 6px;
font-size: 0.9vw;

z-index: 10;
    margin-right: 4.5vw;
    margin-top: 10.3vh;
    position: absolute;
}





.ingameOrders
{
    font-size: 1vw;
        white-space: pre-line;
     border-style: solid;
     border-color: grey; 
      border-width: 0.1vh;
     margin-right: 3vw;
     margin-top: 14vh;
    
    line-height: 2.9vh;
    
    padding: 1.1vw;
    
    width: 11vw; 
    float: right;
    
    color: white;
    
    background-color: #181C29;
}


.recapingame
{
     border-radius: 8px;
    float: left;
    margin-left: 4.1vw;
    
    margin-top: 2vh;
    
    padding: 0.3vw;
    font-size: 1vw;
     color: white;
    
    width: 7vw;
    
    text-align: center;
    color: #181C29;
    background-color: white;
    font-weight: bold;
}





.commands
{
    
    
    color: white;
    
    margin-left: 1vw;
    margin-top: 1vw;
    
    height: 20vh;
    
    font-size: 1.3vw;


  
    
}


.buy, .sell
{
   
    
    width: 82vw;
    float: left;
    height: 18.5vh;
    position: absolute;
    margin-left: 16vw;
    margin-top: 64.5vh;
    

    padding-top: 2vh;
    padding-bottom: 1.2vh;
    
    font-size: 1.3vw;
}


.buyBut
{
    cursor: pointer;
    margin-left: 13.4vw;
    margin-top: 3vh;
     
    border-radius: 5px;
     border-color: green;
    color: black;
    background-color: lightgreen;
    
    width: 8vw;
    

    padding: 0.3vw;

}


.goSellBut
{
    cursor: pointer;
    margin-left: 32vw;
    margin-top:3vh;
    width: 5.8vw;
    border-radius: 5px;
    color: lightcoral;
    background-color: lightgray;
    border-color: lightgray;
    padding:0.3vw;
    font-size: 0.8vw;
}



.sellBut
{
    cursor: pointer;
    margin-left: 13.7vw;
    margin-top: 1vh;
     
    border-radius: 5px;
     border-color: green;
    color: black;
    background-color: lightcoral;
    
    width: 7vw;
    

    padding: 0.3vw;


}


.goBuyBut
{
    cursor: pointer;
     margin-left: 1vw;
    margin-top: 1vh;
        width: 6vw;
    border-radius: 5px;
    border-color: lightgray;
    color: green;
    background-color: lightgray;
    font-size: 0.8vw;
    padding:0.3vw;
}


.prix
{
    border-radius: 5px;
    float:left;
    color: black;
    background-color: lightgray;
    width: 8vw;
    margin-left: 13.5vw;
    margin-bottom:2.5vh;
}

.solde
{
    border-radius: 5px;
    color: black;
    background-color: lightgray;
    width: 6.49vw;
    margin-left: 31.6vw;
    padding-top: 0.2vh;
    padding-bottom: 0.2vh;
    margin-bottom:2.5vh;
}

.solde2
{
    border-radius: 5px;
    color: black;
    background-color: lightgray;
    width: 10vw;
    margin-left: 28.8vw;
    padding-top: 0.2vh;
    padding-bottom: 0.2vh;
    margin-bottom:2.5vh;
}

.input
{
    width: 4vw;
    margin-top: 1vh;
    margin-bottom: 1vh;
    margin-left: 1.5vw;
    color: black;

   
    border:none;
    font-size: 0.9vw;
}

.inputInfos
{
    width: 15vw;
    padding: 10px;
    color: white;
    background-color: #FFFFFF00;
    border-radius: 7px;
}

.formRegis
{
    margin-left: 0%;
}





.comLevier
{
    display:none;
    width: 13vw;
    margin-top: 77vh;
    margin-left: 49.5vw;
    height: 17vh;
    font-size: 0.8vw;

    position: absolute;

    background-color: #1C202E;

    z-index: 20;

    top: 0;
    left: 0;
}




.indicLevier
{
    color: white;
    font-size: 1vw;
    margin-left: 2.8vw;
}



.bottomRightSelector
{
    margin-top: -3.3vh;
    margin-left: 5vw;
    color: white;
    font-size: 1vw;
    padding-left: 8px;
    background: #1e3736;
    box-shadow: 0 0 10px 4px black;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    float: left;
    padding-bottom: 0.4vh;
    padding-top: 0.2vh;
}


.pointer
{
    cursor: pointer;
}

.bottomRightSelectorSeparator
{
    margin-left: 3vw;
    padding-right: 8px;
}
.bottomRightSelector2
{
    margin-top: -2.5vh;
    margin-left: 10vw;
    color: white;
    font-size: 1vw;
    padding-left: 22px;
    background: #1e3736;
    box-shadow: 0 0 10px 4px black;
    width: 6%;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    cursor: pointer;
    float: left;
}





#modeAchat
{
    color: #11d89c;
}

#modeVente
{   
    color: #ff5757;
}




.holderPerc
{
    width: 10vw;
    float: left;
    margin-top: 2.5vh;
}


.spanValuePair
{
    margin-left: 8vw;
    position:  absolute;

}







.multiSelec
{
    position: absolute;
    margin-top: 10vh;
    margin-left: 14.3vw;
}

.euro
{
    width: 100%;
    font-size: 1vw;
    margin-left: 1vw;
}


.sliderBuy
{
        margin-top: 3.5vh;
}


.sliderSell
{
    display: none;
    margin-top: 3.5vh;
}

.buysell
{
   margin-top: 3vh;
    margin-left: 28vw;
     width: 9vw;
    
   
}

#buySelec
{
    color: green;
    opacity: 0;
    cursor: pointer;
}


#sellSelec
{
    cursor: pointer;
    color: red;
}

input {
    font-family : inherit;
}

button {
    font-family : inherit;
}



.contentBloc
{
    width: 100%;
    height: 100%;
}




.finishTitle
{
    text-align: center;
    margin-top: 15vh;
    color: white;
}

.finishContent
{
    text-align: center;
    border-radius: 5px;
    color: black;
    background-color: lightgray;
    
    width: 40vw;
    margin-left: 30vw;
    padding: 1vh;
}


.finishPos
{

    
    background-color: #1C202E;
    border-radius: 5px;
    color: white;
    margin-top: 5vh;
    padding: 1vh;
    margin-left: 7vw;
    margin-right: 7vw;
}


.finishRes
{
     background-color: #1C202E;
    border-radius: 5px;
    color: white;
    margin: 2vh;
    padding: 1vh;
    margin-left: 7vw;
    margin-right: 7vw;
    white-space: pre-line;
}


.position
{
    padding: 1vw;
    background-color: gold;
    width: 1.3vw;
    margin-left: 11vw;
    border-radius: 50%;
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: black;
    font-size: 1.3vw;
}



.score
{
    padding: 2vh;
    background-color: lightgreen;
    width: 7vw;
    margin-top: 3vh;
    color: black;
    margin-left: 8vw;
    font-size: 1.3vw;
    border-radius: 5%;
    margin-bottom: 2vh;
}



.rankingGeneral
{
    
    background-color: #34393F;
    border-radius: 5px;
    color: black;
    width: 84.5vw;
    margin-left: 7vw;
    margin-right: 7vw;
    margin-top: -1.2vh;
    font-size: 1.3vw;
    padding-bottom: 2vh;
    padding-top: 1vh;
    border-top-left-radius: 0px;
    float: left;
    
    height: 79vh;
    
}


.renderRanking
{
    margin-left: 2%;
    margin-top: 2%;
    width: 96%;
    height: 95%;
    padding-top: 0.3%;
    overflow-y: auto;
    border-radius: 8px;
}

.blocResultatsContainer
{
    background-color: seagreen;
}



.swicherRanking
{
    display: inline-block;
    margin-top: 7%;
    float: right;
    padding-right: 2%;
    color: orangered;
    cursor: pointer;
}


.blocResultatsComplet
{
    margin-left: 5%;
    float: left;
    width: 50%;
    background: linear-gradient(180deg, #1e3736, #1b1d23);
    box-shadow: 0 0 10px 4px black;
    margin-top: 2%;
    color: white;
    font-weight: 900;
    border-radius: 8px;
}


.blocResultatsCompletRg
{
    margin-left: 5%;

    width: 35%;
    background: linear-gradient(180deg, #1e3736, #1b1d23);
    box-shadow: 0 0 10px 4px black;
    margin-top: 2%;
    float: left;
    text-align: center;
    border-radius: 6px;
}


.rankingGraphDroite
{
    width: 100%;
    height: 0vh;
    display: none;
    color: white;
}


.rankingGraphGauche
{
    width: 100%;
    height: 0vh;
    display: none;
}


.rankingGraphDroiteLogs
{
    display: block;
    padding-left: 3%;
    font-size: 0.9vw;
    white-space: pre-line;
    text-align: left;
    padding-top: 2%;
    overflow-y: auto;
    height: 85%;
}


.rankingGraphDroiteGraph
{
    display: none;
    width: 90%;
    margin-left: 5%;
    margin-top: 2%;
    height: 81%;
    overflow-y: hidden;
}

.bandeauRankingLeftLogs
{
    cursor: pointer;
    margin-left: 3%;
    margin-right: 3%;
    width: 11%;
    font-size: 0.99vw;
}

.bandeauRankingDroiteLogs
{
    display: inline-block;
    width: 20%;
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
    cursor: pointer;
}


.bandeauRankingDroiteGraph
{
    display: inline-block;
    width: 20%;
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
    cursor: pointer;
}

.bandeauRankingDroite
{
    border-bottom-style: solid;
    border-bottom-width: 1px;
}



.rankingGraphLeftGraph
{
    display: none;
    overflow-y: hidden;
    height: 100%;
    margin-left: 2%;
    margin-top: 2%;
}

.dateDureeResults
{
    height: 90%;
    float: left;
    margin-left: 1%;
    padding-left: 1%;
    padding-right: 1%;
}

.percent22
{
    height: 22vh;
}
.percent21
{
    height: 20vh;
}

.contenerScores
{
    margin-top: 2%;
    height: 83%;
    overflow-y: auto;
    margin-left: 2%;
    width: 75%;
    
}





.listScorePlace
{
 font-size: 1.8vw;
 font-weight: 800;
 margin-left: 3%;
 width: 10%;
 display: inline-block;
}


.listScorePseudo
{
   display: inline-block;
   width: 60%;
   cursor: pointer;
}


.listScoreScore
{
  width: 50%;
  overflow-x: auto;
}


.classPrem
{
 color:  #FDE550;
}

.classSec
{
color: #B9E8FD;
}

.classTrois
{
color: #E49961;
}



.placePerso
{
    margin-top: 4vh;
    color: white;
    font-size: 1.5vw;
}

.placePersoValue
{
    margin-top: 2%;
    font-size: 2vw;
}

.ScorePerso
{
    margin-top: 1%;
    color: white;
    font-size: 1.3vw;
    float: left;
    margin-left: 35%;
}


.ScorePersoValue
{
    color: #7AFFD7;
}

.backTour
{
    cursor: pointer;
    background-color: #2B3149;
    color: white;
    font-size: 1vw;

    position:  absolute;
    
    border-radius: 5px;
    border-width: 3px;
    border-color: white;
    border-style: solid;
    
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    padding-left: 1vw;
    padding-right: 1vw;
    
    margin-left: 1vw;
    margin-top: 0vh;
    
    font-size: 1vw;
}





































.tabRank
{
    cursor: pointer;
    background-color: #34393F;
    color: white;
    font-size: 1vw;

    
    
    padding-top: 2vh;
    padding-bottom: 1.9vh;
    padding-left: 3vw;
    padding-right: 3vw;

   
    
    
    margin-top: 2vh;
    
    font-size: 1.3vw;
}



.rankingTab
{
    background-color: #34393F;
    height: 5.5vh;
    padding-top: 2vh;
    margin-left: 7vw;
   
    
    float: left;
}



.partiesJouees
{
    background-color: #34393F;
    height: 5vh;
    padding-top: 2vh;
    padding-left: 1vw;
    margin-right: 8.5vw;
    width: 9vw;
   color: white;
    float: right;
    
    font-size: 1vw;
}

.rankingRes
{
   
   background-color: #2B3149;
    border-radius: 5px;
   margin: 1vw;
   padding-bottom: 3vh;
   padding-top: 1vh;
  
    
 
}




.ligueRank
{
    margin-top: 25%;
    max-width: 85%;

    margin-bottom: 9%;
    
}

.histImg
{
    position: absolute;
    width: 2vw;
    margin-left: -2vw;
    margin-top: -0.5vh;
}

.pseudoScore
{
    float: left;
    
    margin-left: 1vw;
     margin-top: 0.85vw;
    
    background-color: #181C29;

    width: 35vw;
    
    border-color: white;
    border-style: solid;
    border-radius: 5px;
    
    color: white;
    
    padding: 1vw;
    
    
    overflow-y: auto;
    
     transition: all 0.4s ease 0s;
    
}


.contResMulti
{
    position: relative;
    width: 19vw;
}

.pseudoScoreSolo
{
    float: left;
    margin-top: 0.85vw;
    margin-left: 1vw;
    
    padding: 1vw;
    
    width: 35vw;
    
    border-color: white;
    border-style: solid;
    border-radius: 5px;
 transition: all 0.4s ease 0s;
background-color: #181C29;
    
    color: white;
}

.posRes
{
    float: left;
    margin-top: 0.85vw;
    margin-left: 12vw;
    width: 15vw;
    color: white;
    
    text-align: center;
    
    
    margin-right: 5vw;
    
}

.timesRes
{
    
    margin-top: 0.85vw;
    margin-left: 5vw;
    color: white;
    
    


}


.green
{
    color: green;
}


.red
{
    color: indianred;
}
.pseudo
{
    float: left;
    width: 13vw;
    margin-left: 2vw;
    
   /* border-bottom-style:solid;
    border-bottom-color:white;
    border-right-style:solid;
    border-right-color:white;*/
    

}

.showMail
{
    position: absolute;
    margin-left: 1vw;
    margin-top: -1.5vh;
}


.logoutText
{
    color: red;
}
.resToShow
{
    margin-top: 10vh;
    border-style: solid;
    border-radius: 10px;


background-color: #181C29;
}





#tabRankClass
{
     background-color: #34393F;
     color: grey;
}



.scoreRes
{
    float: left;
    width: 10vw;
    margin-left: 6vw;
}



.positionMulti
{
    float: left;
    width: 2vw;
    margin-top: -0.5vh;
    border-style: solid;
    border-radius: 5px;
    margin-right: 1vw;
    
    text-align: right;
}


.gainMulti
{
    margin-left: 1vw;
}




.playerView
{   
   display: none;
}





.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
    margin: 0;
    
    font-family: 'Raleway';

}


.auth form {
  margin-top: 1em;
  max-width: 50%;
}



.footer {
  background-color: Tomato;
}


.displaynone
{
    display: none;
}




.simpleSelec
{
    opacity: 0.3;
}


.bandeau
{
    padding: 10px;
    height: 5%;
    background-color: #1C202E;
}


.resultsEndgame
{
    width: 60vw;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    margin-top: 10vh;
    margin-left: 15vw;
    height: 75vh;
    color: white;
    border-radius: 10px;
    text-align: center;
}
.resultsGroups
{
    width: 60vw;
    margin-top: 1vh;
    height: 35vh;
    background: linear-gradient(152.97deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
    margin-left: 15vw;
    text-align: center;
    padding-top: 1%;
    font-size: 1.2vw;
    color: white;
    white-space: pre-line;
}


.resultsEndgameP
{
    float: left;
     width: 60%;
    white-space: pre-line;
    
}

.resultsEndgameS
{
     width: 8vw;
    white-space: pre-line;

}




.chartsEndgame
{
    width: 40%;
    margin-top: 0%;
    padding-left: 3%;
    height: 30vh;
    overflow: auto;
    float: left;
    border-right-style: solid;
    border-right-color: grey;
    font-size: 1.2vw;
    text-transform: uppercase;
    font-family: 'Teko';
    line-height: 100%;
}




.scoreEndgame
{
    float: left;
    font-size: 1vw;
    padding-top: 0;
    padding-bottom: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;

    width: 50%;
    height: 37vh;
    text-align: center;

    text-transform: uppercase;
    font-family: 'Teko';
    line-height: 100%;


    
}


.scoregame
{
    
    text-align: center;
    
font-size: 1.3vw;
    
}


.scoregameUnit
{
    color: aquamarine;
    margin-top: 5%;
}


.placeEndgame
{
    width: 6vw;
    float: left;
    margin-top: 13vh;
    margin-left: 1vw;
    font-size: 1.2vw;

}


.placegameUnit
{
    color: aquamarine;
    font-weight: bold;
}

.coteEndgame
{
    width: 9vw;
    float: right;
    margin-right: 1vw;

    margin-top: 13.5vh;
}

.cotegameUnit
{   
    
    font-weight: bold;
}

.resumeEndgame
{
    display: none;
    float: left;
    border-style: solid;
    margin-top: 32vh;
    margin-left: 31vw;

    position: absolute;

    padding-top: 4vh;
    padding-bottom: 2vh;
    padding-left: 2vw;
    padding-right: 2vw;

    text-align: center;
    font-size: 1.1vw;

    border-radius: 10px;

    width: 15vw;
    height: 5vh;

    background-color: #1C202E;
    color: lightblue;

}






.xpEndgame
{
    float: left;
    margin-top: 13.25vh;
    margin-left: 6vw;
}



.cashEndgame
{
    
}




.resultsGroupsEm
{
    height: 80%;
    width: 22%;
    margin-left: 1%;
    color: rgb(0, 204, 85);
    float: left;
    margin-top: 1%;
    padding: 5px;
    border-style: solid;
    border-color: rgb(0, 204, 85);
    border-radius: 10px;
    overflow: auto;
}
.resultsGroupsRubis
{
    height: 80%;
    width: 22%;
    margin-left: 1%;
    color: red;
    float: left;
    margin-top: 1%;
    padding: 5px;
    border-style: solid;
    border-color: red;
    border-radius: 10px;
    overflow: auto;
}
.resultsGroupsOr
{
    height: 80%;
    width: 22%;
    margin-left: 1%;
    color: yellow;
    float: left;
    margin-top: 1%;
    padding: 5px;
    border-style: solid;
    border-color: yellow;
    border-radius: 10px;
    overflow: auto;
}
.resultsGroupsSaphir
{
    height: 80%;
    width: 22%;
    margin-left: 1%;
    color: cyan;
    float: left;
    margin-top: 1%;
    padding: 5px;
    border-style: solid;
    border-color: cyan;
    border-radius: 10px;
    overflow: auto;
}


.jpsize
{
    color: white;
text-transform: uppercase;
font-size: 3vw;
font-weight: bolder;
}



.pvContain
{
    background: linear-gradient(115.95deg, #1E242F 100%, #0D1215 100%);
    height: 55vh;
    margin-left: 22vw;
    margin-top: 2%;
    width: 38vw;
    color: white;
    position: absolute;
    border-style: solid;
    border-width: 1px;
    border-color: #E7CE4A;
    border-radius: 15px;
    font-family: 'Teko';
}

.pvPseudo
{
    text-align: center;
    /*
    border-style: solid;
    border-width: 5px;
    border-color: white;
    border-radius: 5px;
    color: black;
    width: 10vw;
    float: right; 
    background-color: white;
    margin-top: 21vh;
    margin-right: 6.4vw;
    padding-top: 1vh;
    padding-bottom: 2vh;
    font-size: 1.2vw*/
}

.pvImages
{
   height: 100%;  
   width: 36%; 
   float: left;
}

.imgCadre2
{

  position: absolute;
  z-index: 1;
  max-height: 100%;
 
}

.imgEmbleme2
{
  
  position: absolute;
  z-index: 2;
  max-height: 100%;
}


.pvInfos
{
    width: 63%;
    height: 100%;
    float: left;
    text-align: center;
}

.pvCitation
{
    color: #E7CE4A;
    font-size: 1.7vw;
    
}

.pvStat
{
    font-size: 1.5vw;
    margin-top: 3%;
}


.ligueRankPP
{
    max-height: 16vh;
    margin-bottom: 7%;
    margin-top: 7%;
    
}

.pvLVL
{
    margin-top: 1%;
    font-size: 1.6vw;
}
.
.imgClose
{
    
}


.profileCloser
{
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 15px;
    width: 2%;
}


.classementResultats
{
    float: left;
    color: white;
    margin-left: 5%;
    padding-left: 3vw;
    margin-top: 3%;
    width: 55%;
    height: 87%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 2vh;
}


.btsChoixLigue
{
    text-align: center;
    margin-bottom: 8%;
    width: 120%;
    margin-left: -15%;
}

#btEmeraude
{
    
    padding: 4px 30px 5px 30px;
    color: black;
    border-radius: 5px;
    margin-left: 4%;
    cursor: pointer;
    background-color: lightgrey;
}


#btDiamant
{
    
    padding: 4px 30px 5px 30px;
    color: black;
    border-radius: 5px;
    margin-left: 4%;
    cursor: pointer;
    background-color: lightgrey;
}

#btOr
{
    
    padding: 4px 30px 5px 30px;
    color: black;
    border-radius: 5px;
    margin-left: 4%;
    cursor: pointer;
    background-color: lightgrey;
}

#btArgent
{
    
    padding: 4px 30px 5px 30px;
    color: black;
    border-radius: 5px;
    margin-left: 4%;
    cursor: pointer;
    background-color: lightgrey;
}

#btAspirant
{
    
    padding: 4px 30px 5px 30px;
    color: black;
    border-radius: 5px;
    margin-left: 4%;
    cursor: pointer;
    background-color: lightgrey;
}




.subLigue
{
    margin-top: 2vh;
    margin-left: 30%;
    
}



.classementResume
{
    float: right;
    color: white;
    margin-right: 5%;
    padding-right: 3%;
    padding-top:2%;
    margin-top: 6%;
    padding-left: 4%;
    width: 17%;
    text-align:center;
    align-content: center;
    line-height: 5vh;
    font-size: 1.6vw; 
    padding-bottom: 2%;



    background: linear-gradient(180deg, #1e3736, #1b1d23);
    box-shadow: 0 0 10px 4px black;


/* Note: backdrop-filter has minimal browser support */
border-radius: 16px;
}


.posRanking
{
    background-color: darkgoldenrod;
    border-style: solid;
    border-radius: 7px;
    width: 3.5vw;
    margin-left: 6vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.ofPlayer
{
    margin-top: 7vh;
    font-size: 1vw;
}




.ligneResultat
{
    width: 55%;
    font-size: 1vw;
    padding-left: 0.5vw;
    padding-top: 0.5vh;
    padding-bottom: 0.3vh;
    display: inline-block;
    margin-top: 0.9vh;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: #11D89C;
}

.resDiam
{
    
    background-color: #5b7079;
    
    
}

.resOr
{
    background-color: #40402e;
    
}

.resAs
{
    background-color:#2b261f;
}
.resArg
{
    
    background-color: #3d3d3d;
}
.placeResultat
{
    width: 14%;
    padding-right: 1vw;
    margin-right: 1vw;
    float: left;
    font-size: 1.3vw;
    font-size: 900;
}



.ppLink
{
    width: 2vw;
    position: absolute;
    margin-top: -1vh;
    cursor: pointer;
    margin-left: -0vw;
}

.ppLink2
{
    width: 2vw;
    position: absolute;
    margin-top: -1vh;
    cursor: pointer;
    margin-left: -2vw;
}

.ligneResultatScore
{
    padding-left: 1vw;
    float: left;
    
}


.ligneResultatPseudo
{
    margin-left: 1vw;
    width: 50%;
    float: left;
    
    position: relative;
    height: auto;
    
}

.StrategiesContainer
{
    display: none;
    position: absolute;
    width: 65vw;
    height: 61.5vh;
    background-color: #1C202E;
    margin-top: -21vh;
    margin-left: 16vw;
    border-style: solid;
    border-color: white;
    padding-top: 0.8vh;

    
}
.pointer {
  cursor: pointer;

}

.bottomRightSelectorSeparator {
  margin-left: 3vw;
  padding-right: 8px;
}
.bottomRightSelector2 {
  margin-top: -2.5vh;
  margin-left: 10vw;
  color: white;
  font-size: 1vw;
  padding-left: 22px;
  background: #1e3736;
  box-shadow: 0 0 10px 4px black;
  width: 6%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
  float: left;
}

#modeAchat {
  color: #11d89c;
}

#modeVente {
  color: #ff5757;
}

.holderPerc {
  width: 10vw;
  float: left;
  margin-top: 2.5vh;
}

.spanValuePair {
  margin-left: 8vw;
  position: absolute;
}

.multiSelec {
  position: absolute;
  margin-top: 10vh;
  margin-left: 14.3vw;
}

.euro {
  width: 100%;
  font-size: 1vw;
  margin-left: 1vw;
}

.sliderBuy {
  margin-top: 3.5vh;
}

.sliderSell {
  display: none;
  margin-top: 3.5vh;
}

.buysell {
  margin-top: 3vh;
  margin-left: 28vw;
  width: 9vw;
}

#buySelec {
  color: green;
  opacity: 0;
  cursor: pointer;
}

#sellSelec {
  cursor: pointer;
  color: red;
}

input {
  font-family: inherit;
}

button {
  font-family: inherit;
}

.contentBloc {
  width: 100%;
  height: 100%;
}

.finishTitle {
  text-align: center;
  margin-top: 15vh;
  color: white;
}

.finishContent {
  text-align: center;
  border-radius: 5px;
  color: black;
  background-color: lightgray;

  width: 40vw;
  margin-left: 30vw;
  padding: 1vh;
}

.finishPos {
  background-color: #1c202e;
  border-radius: 5px;
  color: white;
  margin-top: 5vh;
  padding: 1vh;
  margin-left: 7vw;
  margin-right: 7vw;
}

.finishRes {
  background-color: #1c202e;
  border-radius: 5px;
  color: white;
  margin: 2vh;
  padding: 1vh;
  margin-left: 7vw;
  margin-right: 7vw;
  white-space: pre-line;
}

.position {
  padding: 1vw;
  background-color: gold;
  width: 1.3vw;
  margin-left: 11vw;
  border-radius: 50%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: black;
  font-size: 1.3vw;
}

.score {
  padding: 2vh;
  background-color: lightgreen;
  width: 7vw;
  margin-top: 3vh;
  color: black;
  margin-left: 8vw;
  font-size: 1.3vw;
  border-radius: 5%;
  margin-bottom: 2vh;
}

.rankingGeneral {
  background-color: #34393f;
  border-radius: 5px;
  color: black;
  width: 84.5vw;
  margin-left: 7vw;
  margin-right: 7vw;
  margin-top: -1.2vh;
  font-size: 1.3vw;
  padding-bottom: 2vh;
  padding-top: 1vh;
  border-top-left-radius: 0px;
  float: left;

  height: 79vh;
}

.renderRanking {
  margin-left: 2%;
  margin-top: 2%;
  width: 96%;
  height: 95%;
  padding-top: 0.3%;
  overflow-y: auto;
  border-radius: 8px;
}

.blocResultatsContainer {
  background-color: seagreen;
}

.swicherRanking {
  display: inline-block;
  margin-top: 7%;
  float: right;
  padding-right: 2%;
  color: orangered;
  cursor: pointer;
}

.blocResultatsComplet {
  margin-left: 5%;
  float: left;
  width: 50%;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  margin-top: 2%;
  color: white;
  font-weight: 900;
  border-radius: 8px;
}

.blocResultatsCompletRg {
  margin-left: 5%;

  width: 35%;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  margin-top: 2%;
  float: left;
  text-align: center;
  border-radius: 6px;
}

.rankingGraphDroite {
  width: 100%;
  height: 0vh;
  display: none;
  color: white;
}

.rankingGraphGauche {
  width: 100%;
  height: 0vh;
  display: none;
}

.rankingGraphDroiteLogs {
  display: block;
  padding-left: 3%;
  font-size: 0.9vw;
  white-space: pre-line;
  text-align: left;
  padding-top: 2%;
  overflow-y: auto;
  height: 85%;
}

.rankingGraphDroiteGraph {
  display: none;
  width: 90%;
  margin-left: 5%;
  margin-top: 2%;
  height: 81%;
  overflow-y: hidden;
}

.bandeauRankingLeftLogs {
  cursor: pointer;
  margin-left: 3%;
  margin-right: 3%;
  width: 11%;
  font-size: 0.99vw;
}

.bandeauRankingDroiteLogs {
  display: inline-block;
  width: 20%;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
  cursor: pointer;
}

.bandeauRankingDroiteGraph {
  display: inline-block;
  width: 20%;
  margin-left: 15%;
  margin-right: 15%;
  text-align: center;
  cursor: pointer;
}

.bandeauRankingDroite {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.rankingGraphLeftGraph {
  display: none;
  overflow-y: hidden;
  height: 100%;
  margin-left: 2%;
  margin-top: 2%;
}

.dateDureeResults {
  height: 90%;
  float: left;
  margin-left: 1%;
  padding-left: 1%;
  padding-right: 1%;
}

.percent22 {
  height: 22vh;
}
.percent21 {
  height: 20vh;
}

.contenerScores {
  margin-top: 2%;
  height: 83%;
  overflow-y: auto;
  margin-left: 2%;
  width: 75%;
}

.listScorePlace {
  font-size: 1.8vw;
  font-weight: 800;
  margin-left: 3%;
  width: 10%;
  display: inline-block;
}

.listScorePseudo {
  display: inline-block;
  width: 60%;
  cursor: pointer;
}

.listScoreScore {
  width: 50%;
  overflow-x: auto;
}

.classPrem {
  color: #fde550;
}

.classSec {
  color: #b9e8fd;
}

.classTrois {
  color: #e49961;
}

.placePerso {
  margin-top: 4vh;
  color: white;
  font-size: 1.5vw;
}

.placePersoValue {
  margin-top: 2%;
  font-size: 2vw;
}

.ScorePerso {
  margin-top: 1%;
  color: white;
  font-size: 1.3vw;
  float: left;
  margin-left: 35%;
}

.ScorePersoValue {
  color: #7affd7;
}

.backTour {
  cursor: pointer;
  background-color: #2b3149;
  color: white;
  font-size: 1vw;

  position: absolute;

  border-radius: 5px;
  border-width: 3px;
  border-color: white;
  border-style: solid;

  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  padding-left: 1vw;
  padding-right: 1vw;

  margin-left: 1vw;
  margin-top: 0vh;

  font-size: 1vw;
}

.tabRank {
  cursor: pointer;
  background-color: #34393f;
  color: white;
  font-size: 1vw;

  padding-top: 2vh;
  padding-bottom: 1.9vh;
  padding-left: 3vw;
  padding-right: 3vw;

  margin-top: 2vh;

  font-size: 1.3vw;
}

.rankingTab {
  background-color: #34393f;
  height: 5.5vh;
  padding-top: 2vh;
  margin-left: 7vw;

  float: left;
}

.partiesJouees {
  background-color: #34393f;
  height: 5vh;
  padding-top: 2vh;
  padding-left: 1vw;
  margin-right: 8.5vw;
  width: 9vw;
  color: white;
  float: right;

  font-size: 1vw;
}

.rankingRes {
  background-color: #2b3149;
  border-radius: 5px;
  margin: 1vw;
  padding-bottom: 3vh;
  padding-top: 1vh;
}

.ligueRank {
  margin-top: 25%;
  max-width: 85%;

  margin-bottom: 9%;
}

.histImg {
  position: absolute;
  width: 2vw;
  margin-left: -2vw;
  margin-top: -0.5vh;
}

.pseudoScore {
  float: left;

  margin-left: 1vw;
  margin-top: 0.85vw;

  background-color: #181c29;

  width: 35vw;

  border-color: white;
  border-style: solid;
  border-radius: 5px;

  color: white;

  padding: 1vw;

  overflow-y: auto;

  transition: all 0.4s ease 0s;
}

.contResMulti {
  position: relative;
  width: 19vw;
}

.pseudoScoreSolo {
  float: left;
  margin-top: 0.85vw;
  margin-left: 1vw;

  padding: 1vw;

  width: 35vw;

  border-color: white;
  border-style: solid;
  border-radius: 5px;
  transition: all 0.4s ease 0s;
  background-color: #181c29;

  color: white;
}

.posRes {
  float: left;
  margin-top: 0.85vw;
  margin-left: 12vw;
  width: 15vw;
  color: white;

  text-align: center;

  margin-right: 5vw;
}

.timesRes {
  margin-top: 0.85vw;
  margin-left: 5vw;
  color: white;
}

.green {
  color: green;
}

.red {
  color: indianred;
}
.pseudo {
  float: left;
  width: 13vw;
  margin-left: 2vw;

  /* border-bottom-style:solid;
    border-bottom-color:white;
    border-right-style:solid;
    border-right-color:white;*/
}

.showMail {
  position: absolute;
  margin-left: 1vw;
  margin-top: -1.5vh;
}

.logoutText {
  color: red;
}
.resToShow {
  margin-top: 10vh;
  border-style: solid;
  border-radius: 10px;

  background-color: #181c29;
}

#tabRankClass {
  background-color: #34393f;
  color: grey;
}

.scoreRes {
  float: left;
  width: 10vw;
  margin-left: 6vw;
}

.positionMulti {
  float: left;
  width: 2vw;
  margin-top: -0.5vh;
  border-style: solid;
  border-radius: 5px;
  margin-right: 1vw;

  text-align: right;
}

.gainMulti {
  margin-left: 1vw;
}

.playerView {
  display: none;
}

.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
  margin: 0;

  font-family: "Raleway";
}

.auth form {
  margin-top: 1em;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.auth {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.footer {
  background-color: Tomato;
}

.displaynone {
  display: none;
}

.simpleSelec {
  opacity: 0.3;
}

.bandeau {
  padding: 10px;
  height: 5%;
  background-color: #1c202e;
}

.resultsEndgame {
  width: 60vw;
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-top: 10vh;
  margin-left: 15vw;
  height: 75vh;
  color: white;
  border-radius: 10px;
  text-align: center;
}
.resultsGroups {
  width: 60vw;
  margin-top: 1vh;
  height: 35vh;
  background: linear-gradient(
    152.97deg,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  margin-left: 15vw;
  text-align: center;
  padding-top: 1%;
  font-size: 1.2vw;
  color: white;
  white-space: pre-line;
}

.resultsEndgameP {
  float: left;
  width: 60%;
  white-space: pre-line;
}

.resultsEndgameS {
  width: 8vw;
  white-space: pre-line;
}

.chartsEndgame {
  width: 40%;
  margin-top: 0%;
  padding-left: 3%;
  height: 30vh;
  overflow: auto;
  float: left;
  border-right-style: solid;
  border-right-color: grey;
  font-size: 1.2vw;
  text-transform: uppercase;
  font-family: "Teko";
  line-height: 100%;
}

.scoreEndgame {
  float: left;
  font-size: 1vw;
  padding-top: 0;
  padding-bottom: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;

  width: 50%;
  height: 37vh;
  text-align: center;

  text-transform: uppercase;
  font-family: "Teko";
  line-height: 100%;
}

.scoregame {
  text-align: center;

  font-size: 1.3vw;
}

.scoregameUnit {
  color: aquamarine;
  margin-top: 5%;
}

.placeEndgame {
  width: 6vw;
  float: left;
  margin-top: 13vh;
  margin-left: 1vw;
  font-size: 1.2vw;
}

.placegameUnit {
  color: aquamarine;
  font-weight: bold;
}

.coteEndgame {
  width: 9vw;
  float: right;
  margin-right: 1vw;

  margin-top: 13.5vh;
}

.cotegameUnit {
  font-weight: bold;
}

.resumeEndgame {
  display: none;
  float: left;
  border-style: solid;
  margin-top: 32vh;
  margin-left: 31vw;

  position: absolute;

  padding-top: 4vh;
  padding-bottom: 2vh;
  padding-left: 2vw;
  padding-right: 2vw;

  text-align: center;
  font-size: 1.1vw;

  border-radius: 10px;

  width: 15vw;
  height: 5vh;

  background-color: #1c202e;
  color: lightblue;
}

.xpEndgame {
  float: left;
  margin-top: 13.25vh;
  margin-left: 6vw;
}

.cashEndgame {
}

.resultsGroupsEm {
  height: 80%;
  width: 22%;
  margin-left: 1%;
  color: rgb(0, 204, 85);
  float: left;
  margin-top: 1%;
  padding: 5px;
  border-style: solid;
  border-color: rgb(0, 204, 85);
  border-radius: 10px;
  overflow: auto;
}
.resultsGroupsRubis {
  height: 80%;
  width: 22%;
  margin-left: 1%;
  color: red;
  float: left;
  margin-top: 1%;
  padding: 5px;
  border-style: solid;
  border-color: red;
  border-radius: 10px;
  overflow: auto;
}
.resultsGroupsOr {
  height: 80%;
  width: 22%;
  margin-left: 1%;
  color: yellow;
  float: left;
  margin-top: 1%;
  padding: 5px;
  border-style: solid;
  border-color: yellow;
  border-radius: 10px;
  overflow: auto;
}
.resultsGroupsSaphir {
  height: 80%;
  width: 22%;
  margin-left: 1%;
  color: cyan;
  float: left;
  margin-top: 1%;
  padding: 5px;
  border-style: solid;
  border-color: cyan;
  border-radius: 10px;
  overflow: auto;
}

.jpsize {
  color: white;
  text-transform: uppercase;
  font-size: 2vw;
  font-weight: bolder;
}

/* SUBSCRIBE */

.carousel {
    text-align: center;
    margin-top: 50px;
    color: white;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 20%;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 50px;
}

.ChallengePass {
    border: 1px solid;
    border-color: white;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.ItemPass {
    font-size: 18px;
    border-bottom: 1px solid;
    border-color: white;
    margin-top: 10px;
    padding-bottom: 10px;
}

.ImagePass {
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ObjectifPass {
    font-size: 16px;
    margin: 10px;

}

.button {
    text-align: center;
}

.buttonSubscribe
{
    
    background-color: #2B3149;
    border-radius: 25px;
    border-style: none;
    padding-bottom: 1vh;
    padding-top: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
    width: 20%;
    margin-top: 30px;
    margin-left: 1%;
    margin-right: 1%;
    min-width: 150px;

    font-size: 18px;
    color: white;
    cursor: pointer;

    background: linear-gradient(275.76deg, #FF384A 44.33%, #FF5463 98.56%);
    box-shadow: 0px 4px 20px rgba(255, 56, 74, 0.5);    
    
}


.pvPseudo {
  text-align: center;
  /*
    border-style: solid;
    border-width: 5px;
    border-color: white;
    border-radius: 5px;
    color: black;
    width: 10vw;
    float: right; 
    background-color: white;
    margin-top: 21vh;
    margin-right: 6.4vw;
    padding-top: 1vh;
    padding-bottom: 2vh;
    font-size: 1.2vw*/
}

.pvImages {
  height: 100%;
  width: 36%;
  float: left;
}

.imgCadre2 {
  position: absolute;
  z-index: 1;
  max-height: 100%;
}

.imgEmbleme2 {
  position: absolute;
  z-index: 2;
  max-height: 100%;
}

.imgBordure2 {
  position: absolute;
  z-index: 2;
  max-height: 100%;
}

.pvInfos {
  width: 63%;
  height: 100%;
  float: left;
  text-align: center;
}

.pvCitation {
  color: #e7ce4a;
  font-size: 1.3vw;
}

.pvStat {
  font-size: 1.2vw;
  margin-top: 10%;
}

.ligueRankPP {
  max-height: 16vh;
  margin-bottom: 7%;
  margin-top: 7%;
}

.pvLVL {
  margin-top: 3%;
  font-size: 1.3vw;
}
.imgClose {
}



.classementResultats {
  float: left;
  color: white;
  margin-left: 5%;
  padding-left: 3vw;
  margin-top: 3%;
  width: 55%;
  height: 87%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 2vh;
}

.btsChoixLigue {
  text-align: center;
  margin-bottom: 8%;
  width: 120%;
  margin-left: -15%;
}

#btEmeraude {
  padding: 4px 30px 5px 30px;
  color: black;
  border-radius: 5px;
  margin-left: 4%;
  cursor: pointer;
  background-color: lightgrey;
}

#btDiamant {
  padding: 4px 30px 5px 30px;
  color: black;
  border-radius: 5px;
  margin-left: 4%;
  cursor: pointer;
  background-color: lightgrey;
}

#btOr {
  padding: 4px 30px 5px 30px;
  color: black;
  border-radius: 5px;
  margin-left: 4%;
  cursor: pointer;
  background-color: lightgrey;
}

#btArgent {
  padding: 4px 30px 5px 30px;
  color: black;
  border-radius: 5px;
  margin-left: 4%;
  cursor: pointer;
  background-color: lightgrey;
}

#btAspirant {
  padding: 4px 30px 5px 30px;
  color: black;
  border-radius: 5px;
  margin-left: 4%;
  cursor: pointer;
  background-color: lightgrey;
}

.subLigue {
  margin-top: 2vh;
  margin-left: 30%;
}

.classementResume {
  float: right;
  color: white;
  margin-right: 5%;
  padding-right: 3%;
  padding-top: 2%;
  margin-top: 6%;
  padding-left: 4%;
  width: 17%;
  text-align: center;
  align-content: center;
  line-height: 5vh;
  font-size: 1.6vw;
  padding-bottom: 2%;

  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;

  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;
}

.posRanking {
  background-color: darkgoldenrod;
  border-style: solid;
  border-radius: 7px;
  width: 3.5vw;
  margin-left: 6vw;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.ofPlayer {
  margin-top: 7vh;
  font-size: 1vw;
}

.ligneResultat {
  width: 55%;
  font-size: 1vw;
  padding-left: 0.5vw;
  padding-top: 0.5vh;
  padding-bottom: 0.3vh;
  display: inline-block;
  margin-top: 0.9vh;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #11d89c;
}

.resDiam {
  background-color: #5b7079;
}

.resOr {
  background-color: #40402e;
}

.resAs {
  background-color: #2b261f;
}
.resArg {
  background-color: #3d3d3d;
}
.placeResultat {
  width: 14%;
  padding-right: 1vw;
  margin-right: 1vw;
  float: left;
  font-size: 1.3vw;
  font-size: 900;
}

.ppLink {
  width: 2vw;
  position: absolute;
  margin-top: -1vh;
  cursor: pointer;
  margin-left: -0vw;
}

.ppLink2 {
  width: 2vw;
  position: absolute;
  margin-top: -1vh;
  cursor: pointer;
  margin-left: -2vw;
}

.ligneResultatScore {
  padding-left: 1vw;
  float: left;
}

.ligneResultatPseudo {
  margin-left: 1vw;
  width: 50%;
  float: left;

  position: relative;
  height: auto;
}

.StrategiesContainer {
  display: none;
  position: absolute;
  width: 65vw;
  height: 61.5vh;
  background-color: #1c202e;
  margin-top: -21vh;
  margin-left: 16vw;
  border-style: solid;
  border-color: white;
  padding-top: 0.8vh;
}

.stratSelector {
  padding: 5px;
  border-style: solid;
  border-color: white;
  border-width: 2px;
  margin-top: 1vh;
  margin-left: 1vw;
  color: white;
  width: 6.5vw;
  display: block;
  cursor: pointer;

  background-color: #181c29;
}

.stratScroller {
  overflow: auto;
  height: 58.8vh;
  width: 10.5vw;
  float: left;
  margin-left: 1vw;
  margin-top: -2.5vh;
  margin-right: 2vw;

  background-color: #2b3149;

  border-style: solid;
  border-color: white;
  border-width: 3px;
}

.stratCloser {
  position: absolute;
  top: 1vh;
  right: 0;
  padding: 7px;
  color: white;

  cursor: pointer;
}

.stratDisplayer {
  color: white;
  margin-top: 3vh;
  margin-left: 1vw;
  font-size: 1.8vmin;
  padding-left: 2vw;
  padding-right: 3vw;
  padding-top: 0vh;
}

.nextPage {
  float: right;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 5px;
  border-left-style: solid;
  border-left-color: white;
  border-left-width: 3px;

  border-top-style: solid;
  border-top-color: white;
  border-top-width: 3px;

  background-color: #181c29;

  cursor: pointer;
}

.PrevPage {
  float: right;
  position: absolute;
  bottom: 0vh;
  right: 6vw;
  padding: 5px;
  border-left-style: solid;
  border-left-color: white;
  border-left-width: 3px;
  border-right: solid;
  border-right-color: white;
  border-right-width: 3px;
  border-top-style: solid;
  border-top-color: white;
  border-top-width: 3px;

  background-color: #181c29;

  cursor: pointer;
}

.imgStrat2 {
  max-width: 35vh;
  position: absolute;
  margin-top: 0vh;
  margin-left: 12vw;
}

.imgStrat {
  max-width: 36vh;
  position: absolute;
  margin-top: -24vh;
  margin-left: 12vw;
}

.imgStrat:hover {
  transform: scale(2.5) translateX(-5vw) translateY(-5vh);
  transition: all 0.4s ease 0s;
}

.imgStrat2:hover {
  transform: scale(1.5) translateX(2vw) translateY(-5vh);
  transition: all 0.4s ease 0s;
}

.imgStrat3:hover {
  transform: scale(1.65) translateX(1.5vw) translateY(6vh);
  transition: all 0.4s ease 0s;
}

.ishautgauche:hover {
  transform: scale(3.88) translateX(-0.6vw) translateY(5.4vh);
  transition: all 0.4s ease 0s;
}

.ishautgauche2:hover {
  transform: scale(3) translateX(-0.6vw) translateY(5.4vh);
  transition: all 0.4s ease 0s;
}

.ishautdroite:hover {
  transform: scale(3.88) translateX(-4.2vw) translateY(5.4vh);
  transition: all 0.4s ease 0s;
}
.ishautdroite2:hover {
  transform: scale(3) translateX(-5vw) translateY(5.4vh);
  transition: all 0.4s ease 0s;
}
.ishaut:hover {
  transform: scale(3.88) translateX(-2vw) translateY(6.5vh);
  transition: all 0.4s ease 0s;
}

.iscentre:hover {
  transform: scale(3.88) translateX(-2vw) translateY(1vh);
  transition: all 0.4s ease 0s;
}

.iscentregauche2:hover {
  transform: scale(3) translateX(-0.6vw) translateY(1vh);
  transition: all 0.4s ease 0s;
}
.iscentredroite2:hover {
  transform: scale(3) translateX(-5vw) translateY(1vh);
  transition: all 0.4s ease 0s;
}
.isbasgauche:hover {
  transform: scale(3.8) translateX(-0.5vw) translateY(-4vh);
  transition: all 0.4s;
}

.isbasdroite:hover {
  transform: scale(3.8) translateX(-4vw) translateY(-4vh);
  transition: all 0.4s;
}

.isbas:hover {
  transform: scale(3.88) translateX(-2vw) translateY(-4vh);
  transition: all 0.4s ease 0s;
}

.isbasgauche2:hover {
  transform: scale(3) translateX(-0.6vw) translateY(-6vh);
  transition: all 0.4s ease 0s;
}
.isbasdroite2:hover {
  transform: scale(3) translateX(-5vw) translateY(-6vh);
  transition: all 0.4s ease 0s;
}
.imgStrat3 {
  max-width: 55vh;

  margin-top: 0vh;
  margin-bottom: 0vh;
  margin-left: 7vw;
}
.imgStratH {
  max-height: 33vh;
  max-width: 20vw;
  margin-left: 1vw;
  margin-top: 3vh;
}

.imgStratH2 {
  max-height: 35vh;
  margin-left: 4vw;
  margin-top: -19vh;
  position: absolute;
}

.floatLeft {
  float: left;
  width: 40%;
}

.floatRight {
  float: right;
  width: 59%;
}

.supres {
  width: 40vw;
  margin-bottom: 2vh;
}

.nextreward {
  width: 4.3vw;

  margin-top: -0.6vh;
  margin-left: 1vw;
}

.nextrewtext {
  margin-top: 1vh;
}

.nextrewcont {
  margin-left: 2vw;
  margin-top: 0vh;
  float: left;
}

.nextrewimg {
  margin-top: -3.5vh;
  position: absolute;
}

.textxp {
  margin-left: 1vw;
  margin-top: 0vh;
}

.containerWc {
  text-align: center;
  background-color: white;
  padding-top: 10vh;
  padding-bottom: 10vh;
  padding-left: 10vw;
  padding-right: 10vw;

  margin-top: 10vh;
  margin-bottom: 10vh;
  margin-left: 20vw;
  margin-right: 20vw;

  border-radius: 10px;
}

.notededev {
  display: none;
  top: 0;
  left: 0;
  margin-top: 10vh;
  margin-left: 2vw;
  font-size: 0.75vw;
  position: absolute;
  padding: 10px;

  color: grey;
}

.textTendance {
  width: 18vw;
  height: 18vh;
  float: left;
  text-align: center;
  margin-bottom: 1vh;
}

.imgTendance {
  width: 28vw;
  height: 18vh;
  float: right;

  margin-bottom: 1vh;
  padding-bottom: 0vh;
}

.textEntree {
  width: 18vw;
  height: 18vh;
  float: left;
  text-align: center;
  margin-bottom: 1vh;
}

.imgEntree {
  width: 28vw;
  height: 18vh;
  float: right;

  margin-bottom: 1vh;
  margin-top: 1vh;
}

.textSignal {
  width: 18vw;
  height: 18vh;
  float: left;
  text-align: center;
  margin-bottom: 1vh;
}

.imgSignal {
  width: 28vw;
  height: 20vh;
  float: right;

  margin-bottom: 1vh;
  margin-top: 0.75vh;
}

.imgStratDouble {
  max-height: 100%;
  max-width: 48%;
  margin-left: 0.25vw;
}

.imgTendanceImg {
  height: 15vh;
  width: 100%;
  margin-top: -2.5vh;
  margin-bottom: 0vh;
}

.imgTendanceText {
  width: 100%;
}

.floatLeft50 {
  float: left;
  width: 50%;
  text-align: center;
  color: green;
}

.floatRight50 {
  float: right;
  width: 50%;
  text-align: center;
  color: red;
}

.txtVert {
  color: green;
}

.txtRouge {
  color: red;
}
.imgStratSimple {
  float: left;
  margin-left: 7.5vw;
  width: 45%;
  max-height: 100%;
}

.imgTendanceTextSimpple {
  float: left;
  margin-left: 8vw;
}
.imgTextEntree {
  text-align: center;
  margin-top: 2vh;
}

.eventRanking {
  margin-top: 1vh;
  font-size: 1vw;
  border-style: solid;
  border-width: 1px;
  height: 10vh;
  line-height: 1.5vw;
}

#BSCadd {
  margin-left: 0%;
  margin-right: 0%;
}

#btBSC {
  display: none;
}

.titleGM {
  width: 90%;
  text-align: center;
}

.gmSelecT {
  border-style: none;
  border-radius: 5px;
  padding: 5px;
  margin-top: 5vh;
  margin-left: 8vw;
  cursor: pointer;
  font-size: 1.5vw;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  color: white;
}

.gmSelecTB {
  border-style: none;
  border-radius: 5px;
  padding: 5px;

  margin-left: 2vw;
  cursor: pointer;
  display: inline;
  margin-top: 2vh;
}

.uploadgm {
  border-style: none;
  border-radius: 5px;
  padding: 15px;

  margin-left: 2vw;
  cursor: pointer;
  display: block;
  background-color: blueviolet;
  color: white;
}

.restartgm {
  border-style: none;
  border-radius: 5px;
  padding: 15px;

  margin-left: 2vw;
  cursor: pointer;
  display: block;
  background-color: palevioletred;
  color: white;
}
.infosSimplesTnt {
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  width: 95%;
  margin-left: 3%;
  padding-top: 1%;
  padding-left: 0.6%;
  margin-top: 2%;
  height: 75vh;
}

.logsTnt {
  float: left;
  height: 95%;
  width: 34%;
  margin-left: 1%;
  border-style: solid;
  border-color: white;
  border-width: 1px;
  border-radius: 7px;
  text-align: center;
  text-align: left;
  white-space: pre-line;
  padding-left: 1%;
  overflow-y: auto;
}

.grpTnt {
  float: left;
  height: 50%;
  width: 34%;
  margin-left: 1%;
  border-style: solid;
  border-color: white;
  border-width: 1px;
  border-radius: 7px;
  text-align: center;
  white-space: pre-line;
  padding-left: 1%;
  margin-top: 2%;
}

.titreGrosGroupeRes {
  color: white;
  font-size: 1.5vw;
  margin-right: 50%;
  margin-top: 5%;
}

.groupeRes {
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  width: 23%;
  float: left;
  font-size: 1.2vw;
  margin-left: 2%;
}

.titreGroupeRes {
  font-size: 1.5vw;
  font-weight: 900;
}

.scoreGroupeRes {
  font-weight: 900;
}

.psdGroupeRes {
  margin-top: 5%;
  white-space: pre-line;
}

.setSimpleComplex {
  margin-left: 5%;
  margin-top: 2%;
  font-size: 0.7vw;
  background-color: #ff000000;
  border-style: none;
  cursor: pointer;
  color: grey;
}

.setSimpleComplexCont {
  width: 100%;
  text-align: center;
}

.RankTnt {
  float: left;
  height: 95%;
  width: 30%;
  margin-left: 1%;
  border-style: solid;
  border-color: white;
  border-width: 1px;
  border-radius: 7px;
  text-align: center;
}

.InfsTnt {
  float: left;
  height: 95%;
  width: 30%;
  margin-left: 1%;
  border-style: solid;
  border-color: white;
  border-width: 1px;
  border-radius: 7px;
  text-align: center;
  overflow-y: auto;
}

.gainsFin {
  position: absolute;
  color: #d8d357;
  transform: rotate(-90deg);

  margin-left: 2.4vw;
  margin-top: 48.5vh;

  display: none;
}

.marginleftGM {
  margin-left: 2vw;
}

.inputGM {
  width: 3vw;
}

.gameman {
  color: white;
}

.fieldID4 {
  width: 25vw;
  float: left;
}

.Spectate {
  width: 95vw;
  height: 96vh;
  margin-top: 3vh;
  margin-left: 2.5vw;
  color: white;
}

.SpectateLeftPanel {
  height: 90%;
  width: 30%;
  float: left;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  border-radius: 9px;
}

.SpectateGraph {
  height: 90%;
  width: 68%;
  margin-left: 2%;
  float: left;
  border-radius: 10px;
  background-color: #1e3736;
  box-shadow: 0 0 10px 4px black;
  padding-top: 0.4%;
  text-align: center;
}

.spectateTime {
  position: absolute;
  bottom: 4.5%;
  left: 35%;
  width: 50%;
}

.SpectateLeftPanelTop {
  height: 70%;
  white-space: pre-line;
  font-size: 0.8vw;
  overflow-y: auto;
}

.spectatePairSelector {
  font-size: 1.1vw;
  float: left;
  margin-top: 1.5%;
  margin-left: 50%;
}

.spectateIdSelector {
  font-size: 1.1vw;
  float: left;
  margin-top: 1.5%;
  margin-right: 8%;
  margin-bottom: 3%;
  cursor: pointer;
  text-align: center;
  width: 30%;
}

.butSelPairSpan2 {
  margin-left: 18.5%;
  margin-top: 0.5%;
  padding-left: 0%;

  color: white;

  z-index: 10;
  position: absolute;
  display: inline-block;

  background: linear-gradient(115.95deg, #1e242f 100%, #0d1215 100%);

  font-size: 1.2vw;
  width: 9%;

  border-radius: 10px;
  cursor: pointer;
}

.butSelIdSpan {
  margin-left: 1%;
  margin-top: 0.5%;
  padding-left: 0%;

  color: white;

  z-index: 10;
  position: absolute;
  display: inline-block;

  background: linear-gradient(115.95deg, #1e242f 100%, #0d1215 100%);

  font-size: 1.2vw;
  width: 7%;

  border-radius: 10px;
  cursor: pointer;
}

.SpectateButtonID {
  width: 90%;
  padding-left: 10%;
  padding-right: 10;
  cursor: pointer;
  border-radius: 6px;
}

.SpectateButtonID2 {
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  cursor: pointer;
  border-radius: 6px;
  left: 10%;
}

.SpectateButtonID2:hover {
  background-color: #ff5757;
}

.SpectateButtonID:hover {
  background-color: #ff5757;
}

.SpectateLeftPanelBut {
  height: 1%;
  cursor: pointer;
  text-align: center;
  font-size: 1vw;
  padding-top: 1.5%;
  border-top-style: solid;
  border-color: white;
}

.SpectateLeftPanelBottom {
  height: 26%;
  overflow-y: auto;
  white-space: pre-line;
  padding-left: 2%;
  padding-top: 0%;
}

.SpectateLeftPanelTools {
  height: 2.5vh;
}

.modeSmooth {
  margin-top: 0%;
  text-align: left;
  margin-left: 6%;
  line-height: 1.8;
}
.smoothSelected{
 border-style: solid;
 border-color: white;
 border-width: 1px;
 margin-left: 5%;
 border-radius: 3px;
 padding: 2px;
}

.smoothNotSelected{
 border-style: none;
 border-color: black;
 border-width: 1px;
 margin-left: 5%;
 padding: 2px;

}

.onOffSpace{
  display: inline-block;
  width: 1.1%;
}


.onOffSelected {
 border-style: solid;
 border-color: white;
 border-width: 1px;
 margin-left: 6%;
 border-radius: 3px;
 padding: 0.5% 3.5% 0.5% 3.5%;
}

.onOffNotSelected {
border-style: none;
 border-color: black;
 border-width: 1px;
 margin-left: 6%;
 padding: 0.5% 3.5% 0.5% 3.5%;
}

.SpectateButtonPair {
  float: left;
  width: 7%;
  height: 90%;
  border-style: solid;
  border-radius: 5px;
  padding-left: 1.5%;
  font-weight: 900;
  margin-left: 2%;
  margin-top: 1.5%;
  cursor: pointer;
}

.SpectateLeftPanelRes {
  margin-top: 2%;
}

.grave {
  width: 3%;
}

.spcAfficher {
  width: 3%;
  cursor: pointer;
}

.spcImageAfficherCacher {
  width: 6%;
  cursor: pointer;
  float: right;
}

.spcAfficher10Large {
  width: 6%;
  cursor: pointer;
  float: right;
}

.spcCacher {
  width: 3%;
  margin-left: 1.5%;
  cursor: pointer;
}

.SpectateCont {
}
.SpectatePseudo {
  font-size: 1vw;
  font-weight: 900;
  display: inline-block;
  width: 35%;
}

.SpectatePseudoGrey {
  font-size: 1vw;
  font-weight: 900;
  display: inline-block;
  width: 35%;
  color:dimgray;
}

.SpectateSolde {
  font-size: 0.8vw;
  display: inline-block;
  width: 38%;
  text-align: center;
}

.SpectatePositions {
  width: 6%;
  display: inline-block;
  white-space: pre-line;
}

.SpectateLogsSelector {
   text-align: center;
   width: 4%;
   display: inline-block;
   cursor: pointer;
}

.SpectatePositionsTitles {
  margin-left: 1%;
  margin-right: 0%;
  width: 8%;
  display: inline-block;
}


.spectateControls {
  width: 95%;
  text-align: center;
  padding-bottom: 5%;
}

.SpectatePosition {
  margin-left: 5%;
  display: inline-block;
  margin-top: 1%;
  margin-bottom: 3%;
  font-size: 0.7vw;
}

.spectateLevierMini {
  width: 100%;
}

.keyList {
  max-width: 98%;
  float: left;
  margin-right: 2%;
}

.keyList:hover {
  max-width: 200%;
  float: left;
  margin-right: 2%;
}

.keyListCont {
  float: left;
  width: 11%;
  margin-right: 3%;
  margin-top: 3%;
}

.rightKeys {
  margin-left: 2%;
  margin-top: 1%;
}

.podiumDisplayer {
  margin-left: -7vw;
  margin-top: -4vh;
  width: 107%;
  height: 35vh;
}

.premierPodium {
  position: relative;
  width: 33%;
  height: 100%;
  float: left;
}

.emblemePodium1 {
  max-height: 81%;
  max-width: 70%;
  position: absolute;
  margin-left: 19%;
}

.cadrePodium1 {
  max-height: 81%;
  max-width: 70%;
  position: absolute;
  margin-left: 19%;
  box-shadow: 0 0 10px 4px black;
}

.bordurePodium1 {
  max-height: 81%;
  max-width: 70%;
  position: absolute;
  margin-left: 19%;
  box-shadow: 0 0 10px 4px black;
}

.textePodium1 {
  bottom: 0;
  text-align: center;
  width: 100%;
  position: absolute;
}

.podiumAnoucement {
  width: 100%;
  height: 17%;
}

.preums {
  font-size: 2vw;
  color: goldenrod;
}

.deus {
  font-size: 1.5vw;
  color: silver;
}

.trois {
  font-size: 1.3vw;
  color: red;
}

.tiers {
  display: inline-block;
  width: 33%;
  text-align: center;
}

.prestige {
  width: 100%;
  color: white;
  font-family: "Raleway";
  display: inline-block;
}

.tiersIG {
  display: block;
  width: 33%;
  height: 100%;
  text-align: left;
  position: relative;
  float: left;
}

.keyCenterer {
  margin-bottom: 3%;
}

.expKey{
  width: 17%;
  
  box-shadow: 0 0 10px 3px #32d89f;
}

.podiumEndgameFunc {
  height: 100%;
}

.podiumEndgame {
  height: 53%;
  width: 64%;
  margin-left: 21%;
  margin-bottom: 4%;
}

.cadrePodiumEndgame {
}

.imageCadrePodiumEndgame {
  position: absolute;
  max-height: 75%;
  margin-top: 3%;
  margin-left: 3%;
  box-shadow: 0 0 10px 4px black;
}

.emblemePodiumEndgame {
}

.imageEmblemePodiumEndgame {
  position: absolute;
  max-height: 75%;
  margin-top: 3%;
  margin-left: 3%;
}

.psdPodiumEndgame {
  position: absolute;
  margin-left: 8%;
  bottom: 0;
  text-transform: uppercase;
  font-family: "Teko";
  line-height: 100%;
  font-size: 1.5vw;
}

.scorePodiumEndgame {
  margin-left: 15%;
  text-transform: uppercase;
  font-family: "Teko";
  font-size: 1.3vw;
}

/* web3 */

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChallengePass {
    border: 1px solid;
    border-color: white;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}

.ItemPass {
    font-size: 18px;
    border-bottom: 1px solid;
    border-color: white;
    margin-top: 10px;
    padding-bottom: 10px;
}

.ImagePass {
    width: 200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ObjectifPass {
    font-size: 16px;
    margin: 10px;

}

.button {
    text-align: center;
}

.buttonSubscribe
{
    
    background-color: #2B3149;
    border-radius: 25px;
    border-style: none;
    padding-bottom: 1vh;
    padding-top: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
    width: 20%;
    margin-top: 30px;
    margin-left: 1%;
    margin-right: 1%;
    min-width: 150px;

    font-size: 18px;
    color: white;
    cursor: pointer;

    background: linear-gradient(275.76deg, #FF384A 44.33%, #FF5463 98.56%);
    box-shadow: 0px 4px 20px rgba(255, 56, 74, 0.5);    
    
}


.information {
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    height: 22px;
}

.help {
    color: white;
    text-align: right;
    margin-right: 40px;
    font-size: 16px;
    margin-top: 30px;
    padding-top: 10px;
    text-decoration: none;
    font-weight: bold;
}

.metamask {
    text-align: center;
    margin-top: 20px;
    color: white;
    font-size: 18px;
}

a {
    color: white;
}

@media (max-width: 1460px) {

    .carousel {
        grid-auto-columns: 25%;
    }

    .ChallengePass {
        width: 150px;
    }

    .ImagePass {
        width: 150px;
    }

    .ObjectifPass {
        font-size: 12px;
    }

    .ItemPass {
        font-size: 14px;
    }

    .help {
        font-size: 12px;
    }

    .information {
        height: 18px;
    }
}

@media (max-width: 878px) {

    .carousel {
        grid-auto-columns: 45%;
    }

}

@media (max-width: 507px) {

    .carousel {
        grid-auto-columns: 65%;
    }

    .ChallengePass {
        width: 120px;
    }

    .ImagePass {
        width: 120px;
    }

}



/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.nftMetadata {
    grid-auto-flow: column;

}
/* Add some padding inside the card container */
.login-container .container {
  padding-left: 1%;
  padding-right: 1%;
  padding-bottom: 5%;
  height: 100%;
}

.login-container .container h1 {
  font-size: 2.5rem;
}

.login-container .container form {
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
}

.form-error {
  color: #ff0000; /* red */
  font-size: 1.3rem;
  margin-top: 5px;
}

.login-container button {
  height: 40px;
  font-weight: bold;
  font-size: 15px;
  background-color: #ffffff;
  color: #fff;
  border-radius: 25px;
}

.login-container button:hover {
  background-color: #2b6777;
  cursor: pointer;
}

.login-container button:focus {
  outline: none !important;
}

.logoWeb3 {
  height: 80%;
}

.spinner {
  animation: spinner infinite 0.9s linear;
  height: 90%;
}

.spinner:focus {
  border: none;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.card {
  transition: 0.3s;
  height: 80%;
  width: 80%;
}

@media screen and (max-width: 700px) {
  .login-container {
    justify-content: center;
    background-image: none;
  }
  .card {
    width: 80%;
    align-self: center;
  }
}

@media screen and (max-width: 350px) {
  .card {
    width: 100%;
  }
}

@media   (min-width: 0px) and (max-width: 600px) {
  .auth {
    flex-direction: column;
    align-items: center;
  }
  .auth form {
    max-width: 100%;
  }
  
  .regContLeft img {
    max-width: 100%;
    height: auto;
  }
  .regContLeft {
    max-width: 25%;
    height: auto;
  }
  .regCont {
    width: 100%;
  }
}

@media (max-width: 800px) {
  .regContLeft img {
    max-width: 100%;
    height: auto;
  }
  .regContLeft {
    max-width: 50%;
    height: auto;
  }
  .auth form {
    max-width: 100%;
  }
}

@media (min-width: 800px) and (max-width: 1400px) {
  .regContLeft img {
    max-width: 100%;
    height: auto;
  }
  .regContLeft {
    max-width: 50%;
    height: auto;
  }
  .auth form {
    max-width: 80%;
  }
}




.loadingSpectate{
}

.ResultsDivChecker{
  margin-left: 3%;
  margin-top: 5%;
  width: 15%;
  height: 70vh;
  float: left;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  border-radius: 9px;
  text-align: center;
  color: white;
  overflow-y: auto;
}

.ResultsDivMain{

  overflow-y: auto;
  margin-left: 3%;
  margin-top: 5%;
  width: 70%;
  height: 70vh;
  float: left;
  background: linear-gradient(180deg, #1e3736, #1b1d23);
  box-shadow: 0 0 10px 4px black;
  border-radius: 9px;
  padding-top: 2%;
}


.scoreBarre {
  color: green;
  width: 15%;
  display: inline-block;
}

.scoreValid {
  color: red;
  width: 15%;
  display: inline-block;
}

.scoreLegend {
  color: white;
  width: 15%;
  display: inline-block;
}

.resultsLigne{
  border-style: solid;
  border-radius: 5px;
  width: 80%;
  margin-left: 10%;
  border-color: white;
  border-width: 1px;
  height: 5vh;
}

.resultsPseudo {
  width: 35%;
  display: inline-block;
  font-size: 1.5vw;
  color: white;
  text-align: center;
}

.resultsFinal {
  width: 15%;
  display: inline-block;
  font-size: 1.5vw;
  color: goldenrod;
  text-align: center;
}

.resultsGames {
  display: inline-block;
  font-size: 1.3vw;
  text-align: center;
  width: 45%;
}

.resultsMiniAvatar {
  width: 2%;
}

.resultsMiniFond {
  width: 3.5%;
  position: absolute;
}